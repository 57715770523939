<template>
  <div class="page-layout users">
     <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="page-header">
      <h3>
        <b>
          {{currentId == 0 ? langDec['add'] : langDec['edit']}}
          {{langDec['services'] }}
        </b>
      </h3>
      <b-button size="sm" @click="$router.go(-1)" variant="secondary">{{ langDec['back'] }}</b-button>
    </div>
    <div class="text-center text-danger my-2" v-show="pageIsLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <b-form @submit.stop.prevent="onSubmit"  v-show="!pageIsLoading">
      <b-container class="bv-example-row">
        <b-row>


          <!-- name -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-name" :label="langDec['name'] + ' - ' + langDec['en']" label-for="admin-edit-name">
              <b-form-input
                autofocus
                id="admin-edit-name"
                name="admin-edit-name"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                aria-describedby="input-edit-name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-edit-name">
                {{langDec['nameRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- fr_name -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-fr_name" :label="langDec['name'] + ' - ' + langDec['fr']" label-for="admin-edit-fr_name">
              <b-form-input
                id="admin-edit-fr_name"
                name="admin-edit-fr_name"
                v-model="$v.form.fr_name.$model"
                :state="validateState('fr_name')"
                aria-describedby="input-edit-fr_name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-edit-fr_name" v-if='!$v.form.fr_name.required'>
                {{langDec['nameRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>




          <!-- description -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-description" :label="langDec['description'] + ' - ' + langDec['en']" label-for="admin-edit-description">
              <!-- <b-form-textarea
                rows="4"
                id="admin-edit-description"
                name="admin-edit-description"
                v-model="$v.form.description.$model"
                :state="validateState('description')"
                aria-describedby="input-edit-description"
              ></b-form-textarea>
              <b-form-invalid-feedback id="input-edit-description">
                {{langDec['descriptionRequired']}}
              </b-form-invalid-feedback> -->
              <vue-editor v-model="form.description"></vue-editor>

            </b-form-group>
          </b-col>


          <!-- fr_description -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-fr_description" :label="langDec['description'] + ' - ' + langDec['fr']" label-for="admin-edit-fr_description">
              <!-- <b-form-textarea
                rows="4"
                id="admin-edit-fr_description"
                name="admin-edit-fr_description"
                v-model="$v.form.fr_description.$model"
                :state="validateState('fr_description')"
                aria-describedby="input-edit-fr_description"
              ></b-form-textarea>
              <b-form-invalid-feedback id="input-edit-fr_description">
                {{langDec['descriptionRequired']}}
              </b-form-invalid-feedback> -->
              <vue-editor v-model="form.fr_description"></vue-editor>
            </b-form-group>
          </b-col>



          <!-- recommended_message -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-recommended_message" :label="langDec['message'] + ' - ' + langDec['en']" label-for="admin-edit-recommended_message">
              <b-form-textarea
                rows="4"
                id="admin-edit-recommended_message"
                name="admin-edit-recommended_message"
                v-model="$v.form.recommended_message.$model"
                :state="validateState('recommended_message')"
                aria-describedby="input-edit-recommended_message"
              ></b-form-textarea>
              <b-form-invalid-feedback id="input-edit-recommended_message">
                {{langDec['messageRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- fr_recommended_message -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-fr_recommended_message" :label="langDec['message'] + ' - ' + langDec['fr']" label-for="admin-edit-fr_recommended_message">
              <b-form-textarea
                rows="4"
                id="admin-edit-fr_recommended_message"
                name="admin-edit-fr_recommended_message"
                v-model="$v.form.fr_recommended_message.$model"
                :state="validateState('fr_recommended_message')"
                aria-describedby="input-edit-fr_recommended_message"
              ></b-form-textarea>
              <b-form-invalid-feedback id="input-edit-fr_recommended_message">
                {{langDec['messageRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          

          <!-- priority -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-priority" :label="langDec['sortNumber'] + ' - ' + langDec['fr']" label-for="admin-edit-priority">
              <b-form-input
                type="number"
                min="0"
                id="admin-edit-priority"
                name="admin-edit-priority"
                v-model="$v.form.priority.$model"
                :state="validateState('priority')"
                aria-describedby="input-edit-priority"
              ></b-form-input>
              <b-form-invalid-feedback id="input-edit-priority">
                {{langDec['priorityRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <b-col lg="6" md="12" >
            <div class="text-center text-danger my-2 mt-5" v-show="imageIsLoading">
              <b-spinner class="align-middle"></b-spinner>
            </div>
            <b-form-group v-show="!imageIsLoading" v-if="form.image == null" id="group-admin-edit-imageFile" :label="langDec['image']" label-for="admin-edit-imageFile">
              <b-form-file
                @change="addImage"
                accept="image/*"
                id="admin-edit-imageFile"
                name="admin-edit-imageFile"
                v-model="imageFile"
                aria-describedby="input-edit-image"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <span style="color: #dc3545;" v-if="imageIsRequired">
                {{ langDec['imageRequired'] }}
              </span>
            </b-form-group>
            <div v-show="!imageIsLoading"  v-else>
              <div>
                <span>
                  {{ langDec['image'] }}
                </span>
              </div>
              <div class="image-container mt-2">
                <div class="remove-button">
                  <b-button size="sm" @click="removeImage">
                    <i class="fa fa-remove"></i>
                  </b-button>
                </div>
                <b-img style="width:150px;object-fit: contain;" :src="form.image" rounded alt="Rounded image"></b-img>
              </div>
            </div>
          </b-col>

         
        </b-row>
        <b-button :disabled="imageIsLoading" class="mt-3" type="submit" variant="primary">{{ currentId == 0  ? langDec['add'] : langDec['save']}}</b-button>
      </b-container>
    </b-form>
  </div>
</template>

<script>
  import { sendRequest } from "../../../utils/RequestHelper.js"
  import { validationMixin } from "vuelidate";
  import { required, email, numeric, minLength, maxLength } from "vuelidate/lib/validators";
  import store from "../../../store/index.js"
  import { VueEditor } from "vue2-editor";

  export default {
    mixins: [validationMixin],
    data() {
      return {

        // data
        pageIsLoading: false,
        imageIsLoading: false,
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        langDec: {},
        role: null,
        currentId: null,
        imageIsRequired: false,

        lang: null,
        langIsEng: null,


        // form
        form: {
          name: null,
          fr_name: null,
          description: null,
          fr_description: null,
          recommended_message: null,
          fr_recommended_message: null,
          image: null,
          priority: null,
        },
        imageFile: null,
      };
    },


    // validations
    validations: {
      form: {
        name: {
          required
        },
        fr_name: {
          required,
        },
        description: {
          required,
        },
        fr_description: {
          required
        },
        recommended_message: {
          required
        },
        fr_recommended_message: {
          required
        },
        priority: {
          required
        }
      }
    },


    components: {
      VueEditor
    },


    // methods
    methods: {

      // meta
      backToHome(status){
        this.$router.push({
          name: 'services',
          query: {
            role: this.role,
            message: status
          }
        })
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },


      // form
      addImage() {
        this.imageIsLoading = true;
        setTimeout(() => {
          let data = new FormData();
          data.append("image", this.imageFile);

          var successCallback = (response) => {
            this.form.image = response.data.data.path;
            this.imageIsLoading = false;
            this.imageIsRequired = false;
          };
          var errorCallback = (error) => {
            let errors = error.response.data;
            let errorMessage = ''
            for (const prop in errors) {
              if (Object.hasOwn(errors, prop)) {
                errorMessage = errors[prop] ;
                this.alertText = errorMessage
                this.alertType = "danger"
                this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
              }
            }
          };
          sendRequest( "post", "dashboard/upload-image", true, data, successCallback, errorCallback, true);
        }, 1000);
      },
      removeImage() {
        this.form.image = null
      },
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },

      onSubmit() {
        if(this.form.image == null){
          this.imageIsRequired = true;
          this.alertText = 'Image is required'
          this.alertType = "danger"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        }
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return; 
        }
        if(this.form.image){
          if(this.currentId == 0){
            this.createItem();
          }
          else{
            this.saveUser();
          }
        }
        else{
          this.imageIsRequired = true;
          this.alertText = 'Image is required'
          this.alertType = "danger"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        }
      },


      createItem() {
        this.pageIsLoading = true;
        this.form.type = 'item';
        var successCallback = () => {
          this.pageIsLoading = false;
          this.alertType = "success"
          this.alertText = this.langDec['savedMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.backToHome('save');
        };
        var errorCallback = (error) => {

          let errors = error.response.data;
          let errorMessage = ''
          for (const prop in errors) {
            if (Object.hasOwn(errors, prop)) {
              errorMessage = errors[prop] ;
              this.alertText = errorMessage
              this.alertType = "danger"
              this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            }
          }
          this.pageIsLoading = false;
          
        };
        sendRequest('post', 'dashboard/products', true, this.form, successCallback, errorCallback);

      },
      saveUser() {
        this.pageIsLoading = true;

        this.form.type = 'service';
        var successCallback = () => {
          this.pageIsLoading = false;
          this.alertType = "success"
          this.alertText = this.langDec['savedMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.backToHome('save');
        };
        var errorCallback = (error) => {
          let errors = error.response.data;
          let errorMessage = ''
          for (const prop in errors) {
            if (Object.hasOwn(errors, prop)) {
              errorMessage = errors[prop] ;
              this.alertText = errorMessage
              this.alertType = "danger"
              this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            }
          }
          this.pageIsLoading = false;
        };
        sendRequest('put', 'dashboard/products/' + this.$route.params.id, true, this.form, successCallback, errorCallback);
      },
      loadData() {
        this.pageIsLoading = true;
        var successCallback = (response) => {
          this.pageIsLoading = false;
          this.form = response.data.data;
        };
        var errorCallback = (error) => {
          if(error.response.status == 404) {
            this.$router.push({
              name: 'home'
            })
          }
          this.pageIsLoading = false;
          this.alertType = "danger"
          this.alertText = this.langDec['errorMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', 'dashboard/products/' + this.$route.params.id, true, null, successCallback, errorCallback);
      }
    },


    // created
    created() {
      this.langDec = store.getters.getLangData
      this.currentId = this.$route.params.id
      if(this.currentId != 0) {
        this.loadData();
      }
      this.lang = store.getters.getlang;
      this.langIsEng = false;
    }
  };
</script>
<style scoped>
  .image-container {
    position: relative;
    display: inline-block;
  }

  .remove-button {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
  }
</style>