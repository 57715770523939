<template>
  <div class="login-page">
    <div class="container">
      <div class="panel panel-default login-panel">
        <div>
          <b-row>

            <!-- panel -->
            <b-col lg="12" md="12">
              <div class="panel-heading">

                <!-- logo -->
                <div  @click="setIP()">
                  <img width="120px"  style="height:120px;object-fit: contain;box-shadow:1px 1px 5px;border-radius: 50%;margin-bottom: 20px;" src="../assets/images/logo.webp">
                </div>
                <h5 style="margin-bottom: 0;">EGYPAL - {{langDec['dashboard']}}</h5>
                <small class="text-muted"> v 1.0.0</small>
              </div>
            </b-col>
          </b-row>

          <!-- login form -->
          <login></login>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import login from "../components/adminLogin.vue"
  import store from "../store/index.js"

  export default {
    data() {
      return {
        api: null,
        stagingURL: null,
        langDec: null,
      };
    },


    // components
    components: {
      login,
    },


    // methods
    methods: {
      setIP(){
        let apiUrl = null
        if(!store.getters.getAPIURL) {
          const protocol = window.location.protocol; // "http:" or "https:"
          const hostname = window.location.hostname; // domain name
          apiUrl = protocol + "//" + hostname + '/'
          apiUrl += 'api/'
        }
        else{
          apiUrl = store.getters.getAPIURL
        }
        let ip = prompt("Please enter your IP !", apiUrl);

        if (ip.includes('http')) {
          if(ip[ip.length-1] != '/'){
            ip += '/'
          }
          if(!ip.includes('api')){
            ip += 'api/'
          }
          
          ip = ip.replace('admin.','api.');

          store.commit("SET_APIURL", ip);
          location.reload()
        }
        else{
          alert('No http or https in URL')
        }
      }
    },


    // created
    created() {
      this.langDec = store.getters.getLangData
      this.api = store.getters.getAPIURL
      if(!store.getters.getAPIURL){
        const protocol = window.location.protocol; // "http:" or "https:"
        const hostname = window.location.hostname; // domain name
        let apiUrl = protocol + "//" + hostname + '/api/'
        apiUrl = apiUrl.replace('admin.', 'api.');
        store.commit("SET_APIURL", apiUrl);
      } 
    }
  }
</script>

<style>
  .login-page{
    width: 95% !important;
    padding-top: 50px !important;
  }
  .login-panel{
    width: 40%;
    margin: auto;
  }
  .panel-heading{
    text-align: center;
  }
  .form-footer{
    padding-top: 20px;
  }
  .login-btn{
    display: block !important;
    margin: auto !important;
  }
  @media (max-width: 950px) {
    .login-page{
      width: 93% !important;
    }
    .login-panel{
      width: 70%;
      margin: auto;
    }
  }
  @media (max-width: 650px) {
    .login-page{
      width: 90% !important;
    }
    .login-panel{
      width: 100%;
      margin: auto;
    }
    .panel-heading h4{
      font-size: 20px;
    }
    .pageContent{
      padding: 30px 30px !important;
    }
  }
</style>