<template>
  <div id="app" :lang="lang">
    <nav-bar :accountType='accountType'></nav-bar>
    <router-view class="pageContent"></router-view>
  </div>
</template>

<script>
import { sendRequest } from "./utils/RequestHelper.js"
import navBar from "./components/navBar/nav.vue";
import store from "./store/index.js"
import {messaging} from "./firebase/firebase"

export default {
  data() {
    return {
      form: "",
      accountType: null
    };
  },
  components:{
    navBar
  },

  methods: {
  },
  created() {
    store.commit("SET_LANG", "en");
    this.lang = store.getters.getlang
    this.langIsEng = this.lang == 'en'
    store.commit("SET_INTERNATIONALIZATION", this.lang)
  }
};
</script>

<style scoped lang="scss">
  @font-face {
    font-family: "cairo-bold";
    src: url("../src/assets/fonts/Cairo-Bold.ttf");
  }
  @font-face {
    font-family: "cairo-regular";
    src: url("../src/assets/fonts/Cairo-Regular.ttf");
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "cairo-regular" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  :lang(en).pageContent{
    width: 94%;
    float: right;
    padding: 3px 20px;
    margin-top: 66px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: white;
    border-radius: 5px;
    box-shadow:#c2c2c2 1px 0px 6px 0px;
  }
  :lang(ar).pageContent{
    width: 94%;
    float: left;
    padding: 3px 10px;
    margin-top: 66px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: white;
    border-radius: 5px;
    box-shadow:#c2c2c2 1px 0px 6px 0px;
  }
  @media (max-width: 1100px) {
    :lang(en).pageContent{
      width: 89%;
      float: right;
      padding: 10px;
      margin-top: 70px;
    }
    :lang(ar).pageContent{
      width: 89%;
      float: left;
      padding: 10px;
      margin-top: 70px;
    }
  }
  @media (max-width: 800px) {
    :lang(en).pageContent{
      width: 86%;
      float: right;
    }
    :lang(ar).pageContent{
      width: 86%;
      float: left;
    }
  }
  @media (max-width: 599px) {
    :lang(en).pageContent{
      float: right;
    }
    :lang(ar).pageContent{
      float: left;
    }
  }
</style>
