const getters  = {
  authToken(state) {
    return state.token
  },
  username(state) {
    return state.user_name
  },
  accoutType(state) {
    return state.account_type
  },
  userId(state) {
    return state.user_id
  },
  getlang(state){
    return state.lang
  },
  getLangData(state){
    return state.langData
  },
  getAPIURL(state){
    return state.apiUrl
  },
}
export default getters 
