import langObj from '../langs/lang';

const mutations  = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USERNAME(state, user_name) {
    if(user_name == 'admin'){
      user_name = 'adminn'
    }
    state.user_name = user_name;
  },
  SET_ACCOUNT_TYPE(state, account_type) {
    state.account_type = account_type;
  },
  SET_USERID(state, user_id) {
    state.user_id = user_id;
  },
  LOGOUT(state) {
    state.token = null;
    state.user_name = null;
    state.account_type = null;
  },
  SET_LANG(state, lang){
    state.lang = lang;
  },
  SET_INTERNATIONALIZATION(state, data){
    let currentLang = data
    state.langData = langObj[currentLang]
  },
  SET_APIURL(state, data){
    state.apiUrl = data
  },
}
export default mutations 
