import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

// pages
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'

// profile
import EditProfile from '../views/admin/profile/EditProfile.vue'

// users
import Users from '../views/admin/users/Users.vue'
import User from '../views/admin/users/User.vue'

// products
import Products from '../views/admin/products/Products.vue'
import Product from '../views/admin/products/Product.vue'

// services
import Services from '../views/admin/services/Services.vue'
import Service from '../views/admin/services/Service.vue'

// orders
import Orders from '../views/admin/orders/Orders.vue'
import Order from '../views/admin/orders/Order.vue'

// Slides
import Slides from '../views/admin/settings/Slides.vue'

// Settings
import Settings from '../views/admin/settings/Settings.vue'

import routeAuth from "../utils/RoutingHelper.js"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: routeAuth.isLoggedIn,
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  

  // profile
  {
    path: '/edit-profile',
    name: 'editProfile',
    component: EditProfile,
    // // beforeEnter: routeAuth.isLoggedIn,
  },


  // products
  {
    path: '/products',
    name: 'products',
    component: Products,
    beforeEnter: routeAuth.isLoggedIn,
  },
  {
    path: '/product/:id',
    name: 'product',
    component: Product,
    beforeEnter: routeAuth.isLoggedIn,
  },


  // services
  {
    path: '/services',
    name: 'services',
    component: Services,
    beforeEnter: routeAuth.isLoggedIn,
  },
  {
    path: '/service/:id',
    name: 'service',
    component: Service,
    beforeEnter: routeAuth.isLoggedIn,
  },


  // // users
  // {
  //   path: '/users',
  //   name: 'users',
  //   component: Users,
  //   //// beforeEnter: routeAuth.isLoggedIn,
  // },
  // {
  //   path: '/user/:role/:id',
  //   name: 'user',
  //   component: User,
  //   // // beforeEnter: routeAuth.isLoggedIn,
  // },


  // orders
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    beforeEnter: routeAuth.isLoggedIn,
  },  
  {
    path: '/order/:id',
    name: 'order',
    component: Order,
    beforeEnter: routeAuth.isLoggedIn,
  },


  // settings
  {
    path: '/slides',
    name: 'slides',
    component: Slides,
    beforeEnter: routeAuth.isLoggedIn,
  },

  
  // Settings
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    beforeEnter: routeAuth.isLoggedIn,
  },


  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    beforeEnter: routeAuth.isLoggedIn,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
