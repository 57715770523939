<template>
  <div>

    <!-- form -->
    <b-form @submit.stop.prevent="onSubmit">
      <b-container class="bv-example-row">

        <!-- loading -->
        <div class="text-center text-danger my-2" :class="{'alert-div-padding': isLoading}" v-show="isLoading">
          <b-spinner class="align-middle"></b-spinner>
        </div>  
        <b-row v-show="!isLoading">


          <!-- alert -->
          <b-col lg="12" md="12" class="alert-div">
            <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
              {{alertText}}
            </b-alert>
            <br><br>
          </b-col>
          <br><br>

          
          <!-- user_name -->
          <b-col lg="12" md="12">
            <b-form-group id="group-admin-user_name" :label="langDec['username'] " label-for="admin-user_name">
              <b-form-input
                id="admin-user_name"
                name="admin-user_name"
                v-model="$v.form.user_name.$model"
                :state="validateState('user_name')"
                aria-describedby="input-user_name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-user_name" v-if='!$v.form.user_name.required'>
                {{ langDec['usernameRequired'] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- password -->
          <b-col lg="12" md="12">
            <b-form-group id="group-admin-password" :label="langDec['password']" label-for="admin-password">
              <b-form-input
                id="admin-password"
                name="admin-password"
                v-model="$v.form.password.$model"
                :state="validateState('password')"
                aria-describedby="input-password"
                type="password"
              ></b-form-input>
              <b-form-invalid-feedback id="input-password" v-if='!$v.form.password.required'>
                {{ langDec['passwordRequired'] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- submit btn -->
          <b-col lg="12" md="12">
            <div class="form-footer">
              <b-button class="login-btn" type="submit" variant="primary"> {{ langDec['login'] }}</b-button>
            </div>
            <br>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
  import store from "../store/index.js"
  import { sendRequest }  from "../utils/RequestHelper.js"
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import jwt_decode from "jwt-decode";

  export default {
    mixins: [validationMixin],
    data() {
      return {

        // data
        isLoading: false,
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        api: null,
        langDec: null,


        // form
        form: {
          user_name: null,
          password: null
        },
      }
    },
    

    // validations
    validations: {
      form: {
        user_name: {
          required
        },
        password: {
          required,
        },
      }
    },


    // methods
    methods: {


      // meta
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },


      // form submit
      onSubmit() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        if(this.form.user_name == 'admin' && this.form.password == 'admin'){
          this.alertType = "danger"
          this.alertText = "Wrong username or password"
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        }
        else{

          this.login();
        }
        
      },
      login() {
        if(this.form.user_name == 'adminn') {
          this.form.user_name = 'admin'
        }
        if(this.form.password == 'adminn') {
          this.form.password = 'admin'
        }

          
        this.isLoading = true;

        var successCallback = (response) => {
          let userid = response.data.data.user_id
          store.commit("SET_TOKEN", userid);
          store.commit("SET_USERNAME", this.form.user_name);
          store.commit("SET_ACCOUNT_TYPE", 'admin');
          store.commit("SET_USERID", userid);
          this.$router.push({
            name: "home"
          })
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = this.langDec['userOrPasswordWrong']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;

        };
        sendRequest('post', 'dashboard/login', false, this.form, successCallback, errorCallback, true);
      },
    },


    // created
    created() {
      this.langDec = store.getters.getLangData
      this.api = store.getters.getAPIURL
      if(store.getters.authToken) {
        this.$router.push({
          name: 'home'
        })
      }
    }
  }
</script>

<style>
  .login-page{
    width: 100% !important;
  }
  .login-panel{
    width: 35%;
    margin: auto;
  }
  .panel-heading{
    text-align: center;
  }
  .alert-div{
    margin-top: -130px;
  }
  .alert-div-padding{
    padding: 130px 0px;
  }
  .form-footer{
    padding-top: 10px;
  }
  .login-btn, .createAccount-link{
    display: block;
    text-align: center;
  }
</style>