<template>
  <div class="page-layout items">
     <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    
    <div class="page-header">
      
      <h3>
        <b>
          {{langDec['orders'] }}
        </b>
        <b-button type="button" size="lg" variant="outline-secondary" class="btn btn-primary-outlined options-admin-btn edit-options-admin-btn" @click="refreshData();" ><i class="fa fa-refresh"></i></b-button>
      </h3>


      <!-- <div class="filter-box">
        <div>
          <b-form-input
            @input="loadData(currentPage)"
            v-model="search"
            :placeholder="langDec['search']"
          ></b-form-input>
        </div>
        <span v-if="search != ''" class="filterControl">
          <i @click="search = '';loadData(currentPage)" class="fa fa-times"></i>
        </span>
      </div> -->

    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="row">

          <!-- status filter -->
          <div class="col-lg-3 col-md-3 col-sm-12 mb-3">
            <div class="filter-box">
              <div>
                <b-dropdown
                  block
                  id="dropdown-1"
                  text="Orders type"
                  variant="outline-secondary"
                >
                  <b-dropdown-item
                    @click="updateSelectedStatus(i)"
                    v-for="i in statuses"
                    :key="i.id"
                    >{{ i.text }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <span v-if="selectedStatus.value" class="selected item">
                {{ selectedStatus.text }}
                <!-- <i @click="resetSelectedStatus()" class="closeButton fa fa-times"></i> -->
              </span>
            </div>
          </div>
          
          <!-- date filter -->
          <div class="col-lg-3 col-md-3 col-sm-12 mb-3">
            <div class="filter-box">
              <div>
                <b-form-input
                  type="date"
                  @input="updateDate()"
                  v-model="date"
                ></b-form-input>
              </div>
              <span v-if="date" class="selected item">
                {{ date }}
                <i @click="resetDate()" class="closeButton fa fa-times"></i
              ></span>
            </div>
          </div>
          
        </div>
      </div>
    </div>

    <!-- loading -->
    <div class="text-center text-danger my-2 loadingBox" v-show="pageIsLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>

    <!-- table -->
    <div class="row"  v-show="!pageIsLoading">
      <div class="col-md-12">
        <div class="">
          <div class="">
            <div class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th width="5%">
                      <span class="tbl-data">#</span>
                    </th>
                    <th width="15%">
                      <div class="tbl-data">{{langDec['name'] + ' & ' + langDec['company'] }}</div>
                    </th>
                    <th width="20%">
                      <span class="tbl-data">{{langDec['email'] + ' & ' + langDec['phone'] }}</span>
                    </th>
                    <th width="20%">
                      <span class="tbl-data">{{langDec['product']}}</span>
                    </th>
                    <th>
                      <span class="tbl-data">{{langDec['message']}}</span>
                    </th>
                    <th width="5%" class="td-option">
                      <span clas="tbl-data">	
                        <!-- <b-button @click="goToRouter(0)" size="sm" class="btn btn-success add-admin-btn options-admin-btn" data-toggle="modal" v-b-tooltip.hover.bottom="langDec['add']"><i class="fa fa-plus mx-2"></i>
                        </b-button>	 -->
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no-data-row" v-if="items.length == 0 && tableIsLoading">
                    <td colspan="7">
                      <div class="text-center >text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </td>
                  </tr>
                  <tr class="no-data-row" v-if="items.length == 0 && !tableIsLoading">
                    <td colspan="7">
                      No data
                    </td>
                  </tr>
                  <tr v-for="(item, index) in items" :key="index">
                    <template >
                      <td :class="{'newOrder': item.is_seen == 0}">
                        {{from+index}}
                      </td>
                      <td :class="{'newOrder': item.is_seen == 0}">
                        <div class="tbl-data">{{item.name}}</div>
                        <div class="tbl-data">{{item.company}}</div>
                      </td>
                      <td :class="{'newOrder': item.is_seen == 0}">
                        <div class="tbl-data">{{item.email}}</div>
                        <div class="tbl-data">{{item.phone}}</div>
                      </td>
                      <td :class="{'newOrder': item.is_seen == 0}">
                        <div class="tbl-data"> 
                          <b-badge class="mx-1" :variant="item.product?.type == 'item' ? 'primary':(item.product?.type == 'service' ? 'warning' : 'success')">
                            <small>{{ item.product?.type == 'item' ? 'Product' : (item.product?.type == 'service' ? 'Service' : 'Message') }}</small>
                          </b-badge>
                          <br>
                          <p v-if="item.product" style="margin-bottom: 0px;">{{item?.product?.name}}  
                            <br>
                            <span class="tbl-data">{{item?.created_at.substring(0, 10) + ' | ' + item?.created_at.substring(11, 19)}}</span>
                          </p>
                        </div>
                      </td>
                      <td :class="{'newOrder': item.is_seen == 0}">
                        <div class="tbl-data">{{item?.message}}</div>
                      </td>
                      <td :class="{'newOrder': item.is_seen == 0}" class="td-option">
                        <div class="form-group row-option">
                          <b-button type="button" size="sm" class="btn btn-danger options-admin-btn del-options-admin-btn" data-toggle="modal" data-target="#delAdmin" @click="openDeleteItem(item.id);" v-b-tooltip.hover.bottom="langDec['delete']"><i class="fa fa-trash mx-2"></i></b-button>
                          <b-button v-if="item.is_seen == 0" type="button" variant="primary" size="sm" class="btn btn-primary options-admin-btn edit-options-admin-btn" @click="actionItem(item.id, item, 'seen')" v-b-tooltip.hover.bottom="langDec['done']"><i class="fa fa-eye mx-2"></i></b-button>
                          <!-- <b-button type="button" size="sm" class="btn btn-primary options-admin-btn edit-options-admin-btn" @click="goToRouter(item.id)" v-b-tooltip.hover.bottom="langDec['edit']"><i class="fa fa-edit mx-2"></i></b-button> -->
                        </div>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination v-model="currentPage" :total-rows="total" :per-page="25" align="center" size="sm"></b-pagination>
          </div>
        </div>
      </div>
    </div>


    <!-- modals -->


    <!-- delete modal -->
    <b-modal v-model="delModal" centered title="Delete ">
      <p class="my-4">Want to delete this item?</p>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button auto variant="primary" size="sm" class="float-left mr-1" @click="deleteItem()">
            Delete
          </b-button>
          <b-button variant="secondary" size="sm" class="float-left" @click="delModal=false">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import { sendRequest }  from "../../../utils/RequestHelper.js"
  import store from "../../../store/index.js"

  export default {
    name: 'Home',
    data() {
      return {
        
        // data
        pageIsLoading: false,
        tableIsLoading: true,
        alertType: '',
        alertText: '',
        alertCountdown: 0,
        langDec: {},


        // tables, filters and paginations
        items:[],
        search: '',
        date: '',
        dateValue: null,
        selectedStatus: {
          value: 'item', text: "Products"
        },
        statusValue: 'item',
        statuses: [
          { value: 'item', text: "Products" },
          { value: 'service', text: "Services" },
          { value: 'other', text: "Messages" },
        ],
        currentPage: 1,
        total: null,
        from: 1,

        
        // modals
        currentID: null,
        delModal: false,
      }
    },


    // watch
    watch: {
      currentPage: {
        handler: function(value) {
          this.loadData(value);
        }
      },
      search() {
        if(this.search == '')
          this.resetSearch();
      }
    },


    // methods
    methods: {

      // meta
      goToRouter(id){
        this.$router.push({
          name: 'order',
          params: {
            id
          }
        })
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },


      // filters
      updateDate() {
        this.currentPage = 1
        this.dateValue = this.date
        this.loadData(1, true);
      },
      resetDate() {
        this.currentPage = 1
        this.date = null;
        this.dateValue = null;
        this.loadData(1, true);
      },
      
      // filters
      updateSelectedStatus(status){
        this.currentPage = 1
        this.statusValue = status.value
        this.selectedStatus = status
        this.loadData(1, true);
      },
      resetSelectedStatus(){
        this.currentPage = 1
        this.statusValue = null;
        this.selectedStatus = {};
        this.loadData(1, true);
      },

      
      // load data
      refreshData() {
        this.loadData(this.currentPage);
      },
      resetSearch(){
        this.search = null;
        this.loadData(this.currentPage);
      },  
      loadData(){
        this.pageIsLoading = true;
        this.tableIsLoading = true;

        let query = ""

        // date filter
        if(this.dateValue){
          query += '&date=' + this.dateValue
        }

        // staus filter
         if(this.statusValue) {
          if(this.statusValue == 'other'){
            query += '' 
          }
          else{
            query += '&product_type=' + this.statusValue 
          }          
        }

        var successCallback = (response) => {
          this.items = response.data.data;
          this.total = response.data.total
          this.tableIsLoading = false;
          this.pageIsLoading = false;
        };
        var errorCallback = () => {
          this.tableIsLoading = false;
          this.pageIsLoading = false;
          this.alertType = "danger"
          this.alertText = this.langDec['errorMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', 'dashboard/orders?limit=25&page=' + (this.currentPage) + query, true, null, successCallback, errorCallback);
      },


      // action
      actionItem(id, item, action){
        let form = item
        if(action == 'seen') {
          form.is_seen = 1;
        }
        this.pageIsLoading = true;
        this.tableIsLoading = true;
        var successCallback = () => {
          this.pageIsLoading = false;
          this.alertType = "success"
          this.alertText = this.langDec['savedMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.loadData();
        };
        var errorCallback = (error) => {
          let errors = error.response.data;
          let errorMessage = ''
          for (const prop in errors) {
            if (Object.hasOwn(errors, prop)) {
              errorMessage = errors[prop] ;
              this.alertText = errorMessage
              this.alertType = "danger"
              this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            }
          }
          this.pageIsLoading = false;
        };
        sendRequest('put', 'dashboard/orders/' + id, true, form, successCallback, errorCallback);
      },


      // modals



      // delete modal
      openDeleteItem(id){
        if(id) {
          this.delModal = true;
          this.currentID = id;
        }
      },


      // delete api
      deleteItem() {
        this.delModal = false;
        this.pageIsLoading = true;
        this.tableIsLoading = true;
        if(this.currentID) {
          this.tableIsLoading = true;
          var successCallback = () => {
            this.alertType = "success"
            this.alertText = "Deleted"
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            this.delModal = false;
            this.loadData();
          };
          var errorCallback = () => {
            this.tableIsLoading = false;
            this.alertType = "danger"
            this.alertText = this.langDec['errorMessage']
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          };
          sendRequest('delete', 'dashboard/orders/' + this.currentID, true, null, successCallback, errorCallback);
        }
      },
    },


    // created
    created() {
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
      this.dateValue = this.date = `${year}-${month}-${day}`;

      this.langDec = store.getters.getLangData
      if (this.$route.query.message) {
        this.alertType = "success"
        this.alertText = this.langDec['savedMessage']
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        this.$router.push({
          name: 'users',
        })
      }
      this.loadData(this.currentPage);
    },
  }
</script>
<style scoped>
  @import '../../../assets/css/tableContent.css';
  .newOrder{
    background-color: rgb(217, 247, 170) !important;
    font-weight: bold;
  }
</style>