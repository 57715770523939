<template>
    <div class="page-layout users">

      <!-- alert -->
      <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
        {{alertText}}
      </b-alert>


      <!-- header -->
      <div class="page-header">
        <h3>
          <b>
            {{langDec['settings']}}
          </b>
          <b-button type="button" size="lg" variant="outline-secondary" class="btn btn-primary-outlined options-admin-btn edit-options-admin-btn" @click="refreshData();" ><i class="fa fa-refresh"></i></b-button>
        </h3>
        <b-button size="sm" @click="$router.go(-1)" variant="secondary">{{ langDec['back'] }}</b-button>
      </div>


      <!-- loading -->
      <div class="text-center text-danger my-2" v-show="pageIsLoading">
        <b-spinner class="align-middle"></b-spinner>
      </div>


      <!-- form -->
      <b-form @submit.stop.prevent="onSubmit"  v-show="!pageIsLoading">
        <div>

          <!-- landing page -->
          <b-row class="">
            <b-col lg="12" md="12">
              <b-row class="mb-3 pt-3 pb-3 mx-0 pr-2 pl-2 form-section bv-example-row">
                <b-col lg="12" md="12" class="mb-3">
                  <h4><b>Landing page data</b></h4>
                </b-col>

                
                <!-- slogan -->
                <b-col lg="6" md="12">
                  <b-form-group id="form-edit-title" :label="langDec['slogan'] + ' - EN'" label-for="admin-edit-title">
                    <b-form-input
                      id="admin-edit-title"
                      name="admin-edit-title"  
                      v-model="$v.form.title.$model"
                      :state="validateState('title')"
                      aria-describedby="input-edit-title"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-edit-title">
                      {{ langDec['required'] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>


                <!-- slogan fr -->
                <b-col lg="6" md="12">
                  <b-form-group id="form-edit-fr_title" :label="langDec['slogan'] + ' - FR'" label-for="admin-edit-fr_title">
                    <b-form-input
                      id="admin-edit-fr_title"
                      name="admin-edit-fr_title"  
                      v-model="$v.form.fr_title.$model"
                      :state="validateState('fr_title')"
                      aria-describedby="input-edit-fr_title"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-edit-fr_title">
                      {{ langDec['required'] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>


                <!-- value -->
                <b-col lg="6" md="12">
                  <b-form-group id="form-edit-description" :label="langDec['value'] + ' - EN'" label-for="admin-edit-description">
                    <b-form-textarea
                      rows="4"
                      id="admin-edit-description"
                      name="admin-edit-description"  
                      v-model="$v.form.description.$model"
                      :state="validateState('description')"
                      aria-describedby="input-edit-description"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="input-edit-description">
                      {{ langDec['required'] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>


                <!-- value fr -->
                <b-col lg="6" md="12">
                  <b-form-group id="form-edit-en_description" :label="langDec['value'] + ' - FR'" label-for="admin-edit-en_description">
                    <b-form-textarea
                      rows="4"
                      id="admin-edit-en_description"
                      name="admin-edit-en_description"  
                      v-model="$v.form.en_description.$model"
                      :state="validateState('en_description')"
                      aria-describedby="input-edit-en_description"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="input-edit-en_description">
                      {{ langDec['required'] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>


               <!-- about_us -->
               <b-col lg="6" md="12">
                  <b-form-group id="form-edit-about_us" :label="langDec['about_us'] + ' - EN'" label-for="admin-edit-about_us">
                    <b-form-textarea
                      rows="4"
                      id="admin-edit-about_us"
                      name="admin-edit-about_us"  
                      v-model="form.about_us"
                      aria-describedby="input-edit-about_us"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>


                <!-- about_us fr -->
                <b-col lg="6" md="12">
                  <b-form-group id="form-edit-fr_about_us" :label="langDec['about_us'] + ' - FR'" label-for="admin-edit-fr_about_us">
                    <b-form-textarea
                      rows="4"
                      id="admin-edit-fr_about_us"
                      name="admin-edit-fr_about_us"  
                      v-model="form.fr_about_us"
                      aria-describedby="input-edit-fr_about_us"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>


  
                <!-- logo -->
                <b-col lg="6" md="12" class="mb-3">
                  <b-form-group v-if="form.instagram == null" id="form-edit-instagram" label="Logo" label-for="admin-edit-instagram">
                    <b-form-file
                      @change="addImage('instagram')"
                      accept="image/*"
                      id="admin-edit-instagram"
                      name="admin-edit-instagram"
                      v-model="siteLogo"
                      aria-describedby="input-edit-image"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </b-form-group>
                  <div v-else>
                    <div>
                      <span>
                        Logo image
                      </span>
                    </div>
                    <div class="image-container mt-2">
                      <div class="remove-button">
                        <b-button size="sm" @click="removeImage('instagram')">
                          <i class="fa fa-remove"></i>
                        </b-button>
                      </div>
                      <b-img style="width:150px;object-fit: contain;" :src="form.instagram" rounded alt="Rounded image"></b-img>
                    </div>
                  </div>
                </b-col>


                <!-- aboutUsImage -->
                <b-col lg="6" md="12">
                  <b-form-group v-if="form.youtube == null" id="form-edit-youtube" label="About us image" label-for="admin-edit-youtube">
                    <b-form-file
                      @change="addImage('youtube')"
                      accept="image/*"
                      id="admin-edit-youtube"
                      name="admin-edit-youtube"
                      v-model="aboutUsImage"
                      aria-describedby="input-edit-image"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </b-form-group>
                  <div v-else>
                    <div>
                      <span>
                        About us image
                      </span>
                    </div>
                    <div class="image-container mt-2">
                      <div class="remove-button">
                        <b-button size="sm" @click="removeImage('youtube')">
                          <i class="fa fa-remove"></i>
                        </b-button>
                      </div>
                      <b-img style="width:150px;object-fit: contain;" :src="form.youtube" rounded alt="Rounded image"></b-img>
                    </div>
                  </div>
                </b-col>


                 <!-- facebook -->
                 <b-col lg="6" md="12">
                  <b-form-group id="form-edit-en_header_keywords" :label="'Faceboon URL'" label-for="admin-edit-fr_title">
                    <b-form-input
                      id="admin-edit-en_header_keywords"
                      name="admin-edit-en_header_keywords"  
                      v-model="form.en_header_keywords"
                      aria-describedby="input-edit-en_header_keywords"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <!-- linked in -->
                <b-col lg="6" md="12">
                  <b-form-group id="form-edit-company_profile" :label="'Linked in URL'" label-for="admin-edit-fr_title">
                    <b-form-input
                      id="admin-edit-company_profile"
                      name="admin-edit-company_profile"  
                      v-model="form.company_profile"
                      aria-describedby="input-edit-company_profile"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                  <!-- twitter -->
                  <b-col lg="6" md="12">
                  <b-form-group id="form-edit-google_play_link" :label="'Twitter URL'" label-for="admin-edit-fr_title">
                    <b-form-input
                      id="admin-edit-google_play_link"
                      name="admin-edit-google_play_link"  
                      v-model="form.google_play_link"
                      aria-describedby="input-edit-google_play_link"
                    ></b-form-input>
                  </b-form-group>
                </b-col>


                  <!-- alibaba -->
                  <b-col lg="6" md="12">
                  <b-form-group id="form-edit-header_keywords" :label="'Ali baba URL'" label-for="admin-edit-fr_title">
                    <b-form-input
                      id="admin-edit-header_keywords"
                      name="admin-edit-header_keywords"  
                      v-model="form.header_keywords"
                      aria-describedby="input-edit-header_keywords"
                    ></b-form-input>
                  </b-form-group>
                </b-col>



              </b-row>
            </b-col>
          </b-row>

          <!-- sites -->
          <b-row class="mt-3">
            <b-col lg="6" md="12">
              <b-row class="mb-3 pt-3 pb-3 mx-0 pr-2 pl-2 form-section bv-example-row">
                <b-col lg="12" md="12" class="mb-3">
                  <h4><b>Site 1</b></h4>
                </b-col>
                
                <!-- phone -->
                <b-col lg="6" md="12">
                  <b-form-group id="form-edit-phone" :label="langDec['phone']" label-for="admin-edit-v1">
                    <b-form-input
                      id="admin-edit-phone"
                      name="admin-edit-phone"  
                      type="tel"
                      v-model="$v.form.phone.$model"
                      :state="validateState('phone')"
                      aria-describedby="input-edit-phone"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-edit-phone">
                      {{ langDec['phoneRequired'] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- email -->
                <b-col lg="6" md="12">
                  <b-form-group id="form-edit-email" :label="langDec['email']" label-for="admin-edit-email">
                    <b-form-input
                      id="admin-edit-email"
                      name="admin-edit-email"  
                      type="email"
                      v-model="$v.form.email.$model"
                      :state="validateState('email')"
                      aria-describedby="input-edit-email"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-edit-email">
                      {{ langDec['required'] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>


                <!-- address -->
                <b-col lg="12" md="12">
                  <b-form-group id="form-edit-address" :label="langDec['address']" label-for="admin-edit-address">
                    <b-form-input
                      id="admin-edit-address"
                      name="admin-edit-address"  
                      v-model="$v.form.address.$model"
                      :state="validateState('address')"
                      aria-describedby="input-edit-address"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-edit-address">
                      {{ langDec['addressRequired'] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>


                <!-- location -->
                <b-col lg="12" md="12">
                  <b-form-group id="form-edit-location" :label="langDec['location']" label-for="admin-edit-location">
                    <b-form-input
                      id="admin-edit-location"
                      name="admin-edit-location"  
                      v-model="$v.form.location.$model"
                      :state="validateState('location')"
                      aria-describedby="input-edit-location"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-edit-location">
                      {{ langDec['locationRequired'] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- site 1 image -->
                <b-col lg="12" md="12">
                  <b-form-group v-if="form.footer_meta == null" id="form-edit-footer_meta" label="Site 1 image" label-for="admin-edit-footer_meta">
                    <b-form-file
                      @change="addImage('footer_meta')"
                      accept="image/*"
                      id="admin-edit-footer_meta"
                      name="admin-edit-footer_meta"
                      v-model="site1Image"
                      aria-describedby="input-edit-image"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </b-form-group>
                  <div v-else>
                    <div>
                      <span>
                        Site 1 image
                      </span>
                    </div>
                    <div class="image-container mt-2">
                      <div class="remove-button">
                        <b-button size="sm" @click="removeImage('footer_meta')">
                          <i class="fa fa-remove"></i>
                        </b-button>
                      </div>
                      <b-img style="width:150px;object-fit: contain;" :src="form.footer_meta" rounded alt="Rounded image"></b-img>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="6" md="12">
              <b-row class="mb-3 pt-3 pb-3 mx-0 pr-2 pl-2 form-section bv-example-row">
                <b-col lg="12" md="12"  class="mb-3">
                  <h4><b>Site 2</b></h4>
                </b-col>
                
                <!-- phone -->
                <b-col lg="6" md="12">
                  <b-form-group id="form-edit-phone2" :label="langDec['phone']" label-for="admin-edit-phone2">
                    <b-form-input
                      id="admin-edit-phone2"
                      name="admin-edit-phone2"  
                      type="tel"
                      v-model="form.phone2"
                      aria-describedby="input-edit-phone2"
                    ></b-form-input>
                  </b-form-group>
                </b-col>


                <!-- email -fr -->
                <b-col lg="6" md="12">
                  <b-form-group id="form-edit-fr_slide_description" :label="langDec['email']" label-for="admin-edit-fr_slide_description">
                    <b-form-input
                      id="admin-edit-fr_slide_description"
                      name="admin-edit-fr_slide_description"  
                      type="email"
                      v-model="form.fr_slide_description"
                      aria-describedby="input-edit-fr_slide_description"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-edit-fr_slide_description">
                      {{ langDec['required'] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- address -->
                <b-col lg="12" md="12">
                  <b-form-group id="form-edit-fr_address" :label="langDec['address']" label-for="admin-edit-fr_address">
                    <b-form-input
                      id="admin-edit-fr_address"
                      name="admin-edit-fr_address"  
                      v-model="form.fr_address"
                      aria-describedby="input-edit-fr_address"
                    ></b-form-input>
                  </b-form-group>
                </b-col>


                <!-- location -->
                <b-col lg="12" md="12">
                  <b-form-group id="form-edit-linkedin" :label="langDec['location']" label-for="admin-edit-linkedin">
                    <b-form-input
                      id="admin-edit-linkedin"
                      name="admin-edit-linkedin"  
                      v-model="form.linkedin"
                      aria-describedby="input-edit-linkedin"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <!-- site 2 image -->
                <b-col lg="12" md="12">
                  <b-form-group v-if="form.behance == null" id="form-edit-behance" label="Site 2 image" label-for="admin-edit-behance">
                    <b-form-file
                      @change="addImage('behance')"
                      accept="image/*"
                      id="admin-edit-behance"
                      name="admin-edit-behance"
                      v-model="site2Image"
                      aria-describedby="input-edit-image"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </b-form-group>
                  <div v-else>
                    <div>
                      <span>
                        Site 2 image
                      </span>
                    </div>
                    <div class="image-container mt-2">
                      <div class="remove-button">
                        <b-button size="sm" @click="removeImage('behance')">
                          <i class="fa fa-remove"></i>
                        </b-button>
                      </div>
                      <b-img style="width:150px;object-fit: contain;" :src="form.behance" rounded alt="Rounded image"></b-img>
                    </div>
                  </div>
                </b-col>

              </b-row>
            </b-col>
          </b-row>


          <!-- images -->
          <b-row class="mt-3">
            <b-col lg="12" md="12">
              <b-row class="mb-3 pt-3 pb-3 mx-0 pr-2 pl-2 form-section bv-example-row">
                <b-col lg="12" md="12" class="mb-3">
                  <h4><b>Images</b></h4>
                </b-col>
                
                <!-- Intro imageS -->
                <b-col lg="6" md="12">
                  <b-form-group v-if="form.Visual_identity_samples == null" id="form-edit-Visual_identity_samples" label="Intro image" label-for="admin-edit-Visual_identity_samples">
                    <b-form-file
                      @change="addImage('intro')"
                      accept="image/*"
                      id="admin-edit-Visual_identity_samples"
                      name="admin-edit-Visual_identity_samples"
                      v-model="introImage"
                      aria-describedby="input-edit-image"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </b-form-group>
                  <div v-else>
                    <div>
                      <span>
                        Intro image
                      </span>
                    </div>
                    <div class="image-container mt-2">
                      <div class="remove-button">
                        <b-button size="sm" @click="removeImage('intro')">
                          <i class="fa fa-remove"></i>
                        </b-button>
                      </div>
                      <b-img style="width:150px;object-fit: contain;" :src="form.Visual_identity_samples" rounded alt="Rounded image"></b-img>
                    </div>
                  </div>
                </b-col>

                <b-col lg="6" md="12">
                  <b-form-group v-if="form.facebook == null" id="form-edit-Visual_identity_samples" label="Value image" label-for="admin-edit-Visual_identity_samples">
                    <b-form-file
                      @change="addImage('value')"
                      accept="image/*"
                      id="admin-edit-Visual_identity_samples"
                      name="admin-edit-Visual_identity_samples"
                      v-model="valueImage"
                      aria-describedby="input-edit-image"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </b-form-group>
                  <div v-else>
                    <div>
                      <span>
                        Value image
                      </span>
                    </div>
                    <div class="image-container mt-2">
                      <div class="remove-button">
                        <b-button size="sm" @click="removeImage('value')">
                          <i class="fa fa-remove"></i>
                        </b-button>
                      </div>
                      <b-img style="width:150px;object-fit: contain;" :src="form.facebook" rounded alt="Rounded image"></b-img>
                    </div>
                  </div>
                </b-col>

              </b-row>
            </b-col>
          </b-row>

          <!-- submit -->
          <div class="">
            <b-button type="submit" variant="primary">{{langDec['save']}}</b-button>
          </div>
        </div>
      </b-form>
    </div>
  </template>
  
  <script>
    import { sendRequest } from "../../../utils/RequestHelper.js"
    import { validationMixin } from "vuelidate";
    import { required, email, url, numeric, minLength, maxLength, minValue, maxValue } from "vuelidate/lib/validators";
    import store from "../../../store/index.js"
  
    export default {
      mixins: [validationMixin],
      data() {
        return {

          // data
          lang: null,
          langIsEng: null,
          currentId: null,
          pageIsLoading: false,
          alertCountdown: 0,
          alertType: '',
          alertText: '',


          // form
          form: {

            // sites
            phone: null,
            phone2: null,

            address: null,
            fr_address: null,

            location: null,
            linkedin: null,

            email: null,
            fr_slide_description: null,


            description: null,
            en_description: null,


            // landing page
            title: null,
            fr_title: null,

            about_us: null,
            fr_about_us: null,


            // social media
            en_header_keywords: null,
            company_profile: null,
            google_play_link: null,
            header_keywords: null,
          },

          introImage: null,
          valueImage: null,

          site1Image: null,
          site2Image: null,

          siteLogo: null,

          aboutUsImage: null,
              
        };
      },


      // validations
      validations: {
        form: {
          title: {
            required
          },
          fr_title: {
            required
          },
          description: {
            required
          },
          en_description: {
            required
          },
          phone: {
            required
          },
          address: {
            required
          },
          location: {
            required
          },
          email: {
            required
          }
        }
      },


      // methods
      methods: {


        // form
        addImage(type) {
          this.pageIsLoading = true;
          setTimeout(() => {
            let data = new FormData();
            if(type == 'intro')
              data.append("image", this.introImage);
            else if(type == 'value')
              data.append("image", this.valueImage);
            else if(type == 'footer_meta')
              data.append("image", this.site1Image);
            else if(type == 'behance')
              data.append("image", this.site2Image);
            else if(type == 'instagram')
              data.append("image", this.siteLogo);
            else if(type == 'youtube')
              data.append("image", this.aboutUsImage);
            


            var successCallback = (response) => {
              if(type == 'intro')
                this.form.Visual_identity_samples = response.data.data.path;
              else if(type == 'value')
                this.form.facebook = response.data.data.path;
              else if(type == 'footer_meta')
                this.form.footer_meta = response.data.data.path;
              else if(type == 'behance')
                this.form.behance = response.data.data.path;
              else if(type == 'instagram')
                this.form.instagram = response.data.data.path;
              else if(type == 'youtube')
                this.form.youtube = response.data.data.path;

              this.pageIsLoading = false;
              this.imageIsRequired = false;
            };
            var errorCallback = (error) => {
              let errors = error.response.data;
              let errorMessage = ''
              this.pageIsLoading = false;
              for (const prop in errors) {
                if (Object.hasOwn(errors, prop)) {
                  errorMessage = errors[prop] ;
                  this.alertText = errorMessage
                  this.alertType = "danger"
                  this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
                }
              }
            };
            sendRequest( "post", "dashboard/upload-image", true, data, successCallback, errorCallback, true);
          }, 1000);
        },

        removeImage(type) {
          if(type == 'intro')
            this.form.Visual_identity_samples = null
          else if(type == 'value')
            this.form.facebook = null
          else if(type == 'behance')
            this.form.behance = null
          else if(type == 'footer_meta')
            this.form.footer_meta = null
          else if(type == 'instagram')
            this.form.instagram = null;
          else if(type == 'youtube')
            this.form.youtube = null;
        },


        // meta
        countDownChanged(alertCountdown) {
          this.alertCountdown = alertCountdown
        },
        backToHome(status){
          this.$router.push({
            name: 'dev ',
            query: {
              message: status
            }
          })
        },


        // form
        validateState(name) {
          const { $dirty, $error } = this.$v.form[name];
          return $dirty ? !$error : null;
        },
        onSubmit() {
          this.$v.form.$touch();
          if (this.$v.form.$anyError) {
            return; 
          }
          if(this.currentId == 0){
            this.createItem();
          }
          else{
            this.saveUser();
          }
        },
        saveUser() {
          this.pageIsLoading = true;
          
          var successCallback = () => {
            this.pageIsLoading = false;
            this.loadData();
            this.alertType = "success"
            this.alertText = this.langDec['savedMessage']
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          };
          var errorCallback = (error) => {
            
            let errors = error.response.data;
            let errorMessage = ''
            for (const prop in errors) {
              if (Object.hasOwn(errors, prop)) {
                errorMessage = errors[prop] ;
                this.alertText = errorMessage
                this.alertType = "danger"
                this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
              }
            }
            this.pageIsLoading = false;
          };
          sendRequest('patch', 'dashboard/configrations/1', true, this.form, successCallback, errorCallback);
        },


        // load data
        refreshData() {
          this.loadData();
        },
        loadData() {
          this.pageIsLoading = true;
          var successCallback = (response) => {
            this.pageIsLoading = false;
            let data = response.data.data
            this.form = {

              // homepage
              title: data.title,                // value en
              fr_title: data.fr_title,          // value fr

              about_us: data.about_us,          // what we offer en
              fr_about_us: data.fr_about_us,    // what we offer fr

              description: data.description,    // value en
              en_description: data.en_description,  // value fr



              // locations and sites

              phone: data.phone,                //phone 1
              phone2: data.phone2,              //phone 1

              email: data.email,
              fr_slide_description: data.fr_slide_description,

              address: data.address,            //address 1
              fr_address: data.fr_address,      //address 2

              location: data.location,          //location 1
              linkedin: data.linkedin,           //location 2

              Visual_identity_samples: data.Visual_identity_samples,          //intro image
              facebook: data.facebook,                                        //value image


              footer_meta: data.footer_meta,    // site 1 logo
              behance: data.behance,            // site 2 logo
              instagram: data.instagram,        //site logo
              youtube: data.youtube,             //about us logo


              // social icon url
              en_header_keywords: data.en_header_keywords,  // facebook
              company_profile: data.company_profile,        // linked in
              google_play_link: data.google_play_link,      // twitter
              header_keywords: data.header_keywords,        // ali baba

            }
          };
          var errorCallback = (error) => {
            if(error.response.status == 404) {
              // this.$router.push({
              //   name: 'home'
              // })
            }
            this.pageIsLoading = false;
            this.alertType = "danger"
            this.alertText = this.langDec['errorMessage']
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          };
          sendRequest('get', 'dashboard/configrations/1', true, null, successCallback, errorCallback);
        }
      },


      // created
      created() {
        this.langDec = store.getters.getLangData
        this.loadData();
      }
    };
  </script>