<template>
  <div class="page-layout users">
     <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="page-header">
      <h3><b>{{ langDec['accountSettings'] }}</b></h3>
    </div>


    <!-- loading -->
    <div class="text-center text-danger my-2" v-show="isLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>

    
    <!-- form -->
    <b-form @submit.stop.prevent="onSubmit"  v-show="!isLoading">
      <b-container class="bv-example-row">
        <b-row>

          <!-- usename -->
          <b-col lg="6" md="12">
            <b-form-group id="group-student-edit-user_name" :label="langDec['username']" label-for="student-edit-user_name">
              <b-form-input
                id="student-edit-user_name"
                name="student-edit-user_name"
                v-model="$v.form.user_name.$model"
                :state="validateState('user_name')"
                aria-describedby="input-edit-user_name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-edit-user_name">
                {{ langDec['nameRequired'] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>



          <!-- new password -->
          <b-col lg="6" md="12">
            <b-form-group id="group-student-edit-password" :label="langDec['newPassword']" label-for="student-edit-password">
              <b-form-input
                id="student-edit-password"
                name="student-edit-password"
                v-model="form.password"
                aria-describedby="input-edit-password"
                type="password"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- submit -->
        <b-button type="submit" variant="primary">{{ langDec['save'] }}</b-button>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import { sendRequest }  from "../../../utils/RequestHelper.js"
import { validationMixin } from "vuelidate";
import store from "../../../store/index.js"
import { required, email, numeric, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {

      // data
      isLoading: false,
      alertCountdown: 0,
      alertType: '',
      alertText: '',
      langDec: null,


      // form
      form: {
        user_name: null,
        password: null
      },
    };
  },


  // validations
  validations: {
    form: {
      user_name: {
        required
      },
    }
  },


  // methods
  methods: {

    // meta
    countDownChanged(alertCountdown) {
      this.alertCountdown = alertCountdown
    },


    // form and validation
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      if(this.form.user_name == 'admin') 
        this.form.user_name = null
      this.isLoading = true;
      this.$v.form.$touch();
      
      if (this.$v.form.$anyError) {
        return;
      }
      this.isLoading = true;
      this.saveAdminData();
    },
    saveAdminData() {
     
      this.isLoading = true;
      this.form['name'] = this.form.user_name
      this.form['email'] = this.form.user_name + '@egypal.fr'
      
      if(this.form.password == null) {
        delete this.form.password
      }
      this.form.username = this.form.phone
      var successCallback = () => {
        this.isLoading = false;
        this.alertType = "success"
        this.alertText = this.langDec['savedMessage']
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        this.loadData();
      };
      var errorCallback = (error) => {
        let errors = error.response.data;
          let errorMessage = ''
          for (const prop in errors) {
            if (Object.hasOwn(errors, prop)) {
              errorMessage = errors[prop] ;
              this.alertText = errorMessage
              this.alertType = "danger"
              this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            }
          }
          this.isLoading = false;
      };
      sendRequest('patch', 'dashboard/update-profile/' + this.$store.getters.userId, true, this.form, successCallback, errorCallback);
    },


    // load data
    loadData() {
      this.isLoading = true;
      var successCallback = (response) => {
        let responseData = response?.data?.data; 
        this.form = {
          user_name: responseData.user_name,
          password: null,
          name: null,
          email: null,
        } 
        this.isLoading = false;
      };
      var errorCallback = (error) => {
        let errors = error.response.data;
          let errorMessage = ''
          for (const prop in errors) {
            if (Object.hasOwn(errors, prop)) {
              errorMessage = errors[prop] ;
              this.alertText = errorMessage
              this.alertType = "danger"
              this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            }
          }
          this.isLoading = false;
      };
      sendRequest('get', 'dashboard/profile/' + this.$store.getters.userId, true, null, successCallback, errorCallback);
    }
  },


  // created
  created() {
    this.langDec = store.getters.getLangData
    this.loadData();
  }
};
</script>