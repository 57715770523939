const actions  = {
  SET_ACCOUNT_TYPE({commit}, account_type) {
    commit("SET_ACCOUNT_TYPE", account_type);
  },
  SET_USERNAME({commit}, user_name) {
    commit("SET_USERNAME", user_name);
  },
  SET_USERID({commit}, user_id) {
    commit("SET_USERID", user_id);
  },
  SET_LANG({commit}, lang) {
    commit("SET_LANG", lang);
  },
  SET_INTERNATIONALIZATION({commit}, data){
    commit("SET_INTERNATIONALIZATION", data)
  },
}
export default actions 
