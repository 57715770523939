import store from "../store/index.js"
const isLoggedIn = function (from, to, next) {
  if(store.getters.authToken == 'adminToken')
  {
    store.commit("LOGOUT");
    next({name:'login'})
  }
  if(!store.getters.authToken) {
    store.commit("LOGOUT");
    next({name:'login'})
  }
  else {
    next();
  }
};

const isAdminLoggedIn = function (from, to, next) {
  if(!store.getters.authToken) {
    store.commit("LOGOUT");
    next({name:'login'})
  }
  else {
    if(store.getters.accoutType != "admin"){
      next({name:'home'})
    }
    next();
  }
};

const isTeacherLoggedIn = function (from, to, next) {
  if(!store.getters.authToken) {
    store.commit("LOGOUT");
    next({name:'login'})
  }
  else {
    if(store.getters.accoutType != "teacher"){
      next({name:'home'})
    }
    next();
  }
};

export default {
  isLoggedIn,
  isAdminLoggedIn,
  isTeacherLoggedIn
};