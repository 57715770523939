export default {

  // form and basics
  login: 'Login',
  add: 'Add',
  save: 'Save',
  done: 'Done',
  edit: 'Edit',
  delete: 'Delete',
  cancel: 'Cancel',
  back: 'Back',
  name: 'Name',
  username: 'Username',
  email: 'Email',
  phone: 'Phone',
  image: 'Image',
  message: 'Message',
  company: 'Company',
  slides: 'Slides',
  title: 'Title',
  slogan: 'Slogan paragraph',
  about_us: 'About us paragraph',
  value: 'Value paragraph',
  description: 'Description',
  address: 'Address',
  location: 'Location',
  password: 'Password',
  oldPassword: 'Old password',
  newPassword: 'New password',
  accountStatus: 'Account status',
  search: 'Search',
  choose: 'Choose...',
  active: 'Active',
  disactive: 'Disactive',
  sortNumber: 'Sort',
  en: 'En',
  fr: 'Fr',

  // success messages
  savedMessage: 'Saved successfully',

  // error messages
  errorMessage: 'Error',

  // required messages
  required: 'Item is required',
  nameRequired: 'Name is required',
  usernameRequired: 'Username is required',
  emailRequired: 'Email is required',
  emailValidRequired: 'Email is not valid',
  messageRequired: 'Message is required',
  imageRequired: 'Image is required',
  companyRequired: 'Company is required',
  phoneRequired: 'Phone is required',
  addressRequired: 'Address is required',
  locationRequired: 'Location is required',
  descriptionRequired: 'Description is required',
  phoneLengthRequired: 'Phone is 11 number',
  passwordRequired: 'Password is required',
  passwordLengthRequired: "Password more than 8 character",
  accountStatusRequired: 'Account status is required',
  userOrPasswordWrong: 'Wrong username or password',


  // nav
  dashboard: 'Dashboard',
  editAccount: 'Edit account',
  logout: 'Logout',
  logoutLabel: 'You want to logout?',

  // side nav
  homepage: 'Homepage',
  admins: 'Admins',
  settings: 'Settings',


  // admins
  admins: 'Admins',
  admin: 'Admin',
  managers: 'managers',
  manager: 'manager',

  
  // account settings
  accountSettings: 'Account settings',


  // settings


  // products
  products: 'Products',
  product: 'Product',


  // services
  services: 'Services',
  service: 'Service',


  // orders
  orders: 'Orders',
  order: 'Order',
  productsOrders: 'Products orders',
  servicesOrders: 'Services orders',
  messagesOrders: 'Messages',


  // locations
  locations: 'Locations',



};