<template>
    <div class="page-layout users">

      <!-- alert -->
      <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
        {{alertText}}
      </b-alert>


      <!-- header -->
      <div class="page-header">
        <h3>
          <b>
            {{langDec['slides']}}
          </b>
          <b-button type="button" size="lg" variant="outline-secondary" class="btn btn-primary-outlined options-admin-btn edit-options-admin-btn" @click="refreshData();" ><i class="fa fa-refresh"></i></b-button>
        </h3>
        <b-button size="sm" @click="$router.go(-1)" variant="secondary">{{ langDec['back'] }}</b-button>
      </div>


      <!-- loading -->
      <div class="text-center text-danger my-2" v-show="pageIsLoading">
        <b-spinner class="align-middle"></b-spinner>
      </div>


      <!-- form -->
      <b-form @submit.stop.prevent="onSubmit"  v-show="!pageIsLoading">


        <!-- images -->
        <b-container fluid class="p-4 bg-dark">
          <b-row v-if="items.length > 0">
            <b-col lg="3" md="3" v-for="i in items" :key="i.id">
              <div class="image-container mt-2">
                <div class="remove-button">
                  <b-button variant="danger" size="sm" @click="removeImage(i.id)">
                    <i class="fa fa-remove"></i>
                  </b-button>
                </div>
                <b-img class="mb-3 mt-3" style="object-fit: contain;" thumbnail fluid :src="i.path" alt="Image 1"></b-img>
              </div>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col lg="12" md="12" style="color: white;">
              No images
            </b-col>
          </b-row>
        </b-container>


        <div>
          <b-row class="mb-3 pt-3 pb-3 mx-0 pr-2 pl-2 form-section">


            <!-- image -->
            <b-col lg="6" md="12" >
              <b-form-group v-if="form.image == null" id="group-admin-edit-imageFile" :label="langDec['image']" label-for="admin-edit-imageFile">
                <b-form-file
                  @change="addImage"
                  accept="image/*"
                  id="admin-edit-imageFile"
                  name="admin-edit-imageFile"
                  v-model="imageFile"
                  aria-describedby="input-edit-image"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                ></b-form-file>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit -->
          <b-button type="submit" variant="primary">{{langDec['save']}}</b-button>
        </div>
      </b-form>
    </div>
  </template>
  
  <script>
    import { sendRequest } from "../../../utils/RequestHelper.js"
    import { validationMixin } from "vuelidate";
    import { required, email, url, numeric, minLength, maxLength, minValue, maxValue } from "vuelidate/lib/validators";
    import store from "../../../store/index.js"
  
    export default {
      mixins: [validationMixin],
      data() {
        return {

          // data
          lang: null,
          langIsEng: null,
          currentId: null,
          pageIsLoading: false,
          alertCountdown: 0,
          alertType: '',
          alertText: '',


          // form
          form: {
            path: null,
          },
          imageFile: null,
          
        };
      },


      // methods
      methods: {

        // meta
        countDownChanged(alertCountdown) {
          this.alertCountdown = alertCountdown
        },
        backToHome(status){
          this.$router.push({
            name: 'dev ',
            query: {
              message: status
            }
          })
        },


        // form
        addImage() {
          this.pageIsLoading = true;
          setTimeout(() => {
            let data = new FormData();
            data.append("image", this.imageFile);

            var successCallback = (response) => {
              this.form.path = response.data.data.path;
              this.saveItem();
            };
            var errorCallback = (error) => {
              let errors = error.response.data;
              let errorMessage = ''
              for (const prop in errors) {
                if (Object.hasOwn(errors, prop)) {
                  errorMessage = errors[prop] ;
                  this.alertText = errorMessage
                  this.alertType = "danger"
                  this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
                }
              }
            };
            sendRequest( "post", "dashboard/upload-image", true, data, successCallback, errorCallback, true);
          }, 1000);
        },
        saveItem() {
          this.pageIsLoading = true;
          
          var successCallback = () => {
            this.pageIsLoading = false;
            this.alertType = "success"
            this.alertText = this.langDec['savedMessage']
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            this.loadData();
          };
          var errorCallback = (error) => {
            
            let errors = error.response.data;
            let errorMessage = ''
            for (const prop in errors) {
              if (Object.hasOwn(errors, prop)) {
                errorMessage = errors[prop] ;
                this.alertText = errorMessage
                this.alertType = "danger"
                this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
              }
            }
            this.pageIsLoading = false;
          };
          sendRequest('post', 'dashboard/sliders', true, this.form, successCallback, errorCallback);
        },
        removeImage(id){
          this.pageIsLoading = true;
          var successCallback = (response) => {
            this.loadData();
          };
          var errorCallback = (error) => {
            this.pageIsLoading = false;
            this.alertType = "danger"
            this.alertText = this.langDec['errorMessage']
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          };
          sendRequest('delete', 'dashboard/sliders/' + id, true, null, successCallback, errorCallback);
        },


        // load data
        refreshData() {
          this.loadData();
        },
        loadData() {
          this.pageIsLoading = true;
          var successCallback = (response) => {
            this.pageIsLoading = false;
            this.items = response.data.data;
            this.imageFile = null;
          };
          var errorCallback = (error) => {
            this.pageIsLoading = false;
            this.alertType = "danger"
            this.alertText = this.langDec['errorMessage']
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          };
          sendRequest('get', 'dashboard/sliders', true, null, successCallback, errorCallback);
        }
      },


      // created
      created() {
        this.langDec = store.getters.getLangData
        this.loadData();
      }
    };
  </script>
  <style scoped>
    .image-container {
      position: relative;
      display: inline-block;
    }

    .remove-button {
      position: absolute;
      top: 17px;
      left: 0;
      padding: 5px;
    }
  </style>