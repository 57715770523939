export default {

  // form and basics
  add: 'اضافة',
  edit: 'تعديل',
  delete: 'حذف',
  back: 'رجوع',
  name: 'الاسم',
  email: 'البريد الالكتروني',
  phone: 'رقم الهاتف',
  password: 'كلمة المرور',
  accountStatus: 'حالة الحساب',
  search: 'بحث',
  active: 'نشط',
  disactive: 'غير نشط',

  // form and basics
  login: 'تسجيل الدخول',
  add: 'اضافة',
  save: 'حفظ',
  edit: 'تعديل',
  delete: 'حذف',
  cancel: 'الفاء',
  back: 'رجوع',
  name: 'الاسم',
  username: 'اسم المستخدم',
  email: 'البريد الالكتروني', 
  phone: 'رقم الهاتف',
  password: 'كلمة المرور',
  oldPassword: 'كلمة المرور السابقة',
  newPassword: 'كلمة المرور الجديدة',
  accountStatus: 'حالة الحساب',
  search: 'بحث',
  choose: 'اختر...',
  active: 'نشط',
  disactive: 'غير نشط',

  // success messages
  savedMessage: 'تم الحفظ',

  // error messages
  errorMessage: 'خطأ',

  // required messages
  nameRequired: 'الاسم مطلوب',
  usernameRequired: 'اسم المستخدم مطلوب',
  emailRequired: 'البريد الالكتروني مطلوب',
  emailValidRequired: 'البريد الالكتروني غير صالح',
  phoneRequired: 'رقم الهاتف مطلوب',
  phoneLengthRequired: 'رقم الهاتف يتكون من 11 رقم',
  passwordRequired: 'كلمة المرور مطلوبة',
  passwordLengthRequired: "كلمة المرور تكون اكثر من 8 احرف",
  accountStatusRequired: 'حالة الحساب مطلوبة',
  userOrPasswordWrong: 'خطأ في اسم المستخدم او كلمة المرور',
  
  // nav
  dashboard: 'لوحة التحكم',
  editAccount: 'تعديل الحساب',
  logout: 'تسجيل الخروج',

  // side nav
  homepage: 'الصفحة الرئيسية',
  admins: 'المسئولون',
  settings: 'الاعدادات',


  // admins
  admins: 'المسئولين',
  admin: 'مسئول',
  managers: 'المديرين',
  manager: 'مدير',

};