<template>
  <div class="home">
    <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div>
      <div class="text-center >text-danger my-5" v-if="isLoading">
        <b-spinner class="align-middle"></b-spinner>
      </div>
      <div class="row pb-4" v-if="!isLoading">

        <!-- today orders -->
        <div class="col-lg-4 col-md-6 col-sm-12 mt-4">
          <b-media tag="li">
            <b-list-group-item variant="success" class="d-flex align-items-center pt-4 pb-4">
              <b-avatar class="ml-3" size="3rem" variant="light">
                <i class="fa fa-shopping-cart" style="color: #34a34e; font-size:1.5rem"></i>
              </b-avatar>
              <h4 class="ml-2 mt-2" style="color: #34a34e;">Today's orders</h4>
              <div class="ml-auto mt-2"><h5  style="color: #34a34e; !important">{{orders?.length}}</h5></div>
            </b-list-group-item>
          </b-media>
        </div>


        <!-- Products -->
        <div class="col-lg-4 col-md-6 col-sm-12 mt-4">
          <b-media tag="li">
            <b-list-group-item variant="primary" class="d-flex align-items-center pt-4 pb-4">
              <b-avatar class="ml-3" size="3rem" variant="light">
                <i class="fa fa-archive" style="color: #004094; font-size:1.5rem"></i>
              </b-avatar>
              <h4 class="ml-2 mt-2" style="color: #004094;">Products</h4>
              <div class="ml-auto mt-2"><h5>{{products.length}}</h5></div>
            </b-list-group-item>
          </b-media>
        </div>

        
        <!-- Services -->
        <div class="col-lg-4 col-md-6 col-sm-12 mt-4">
          <b-media tag="li">
            <b-list-group-item variant="warning" class="d-flex align-items-center pt-4 pb-4">
              <b-avatar class="ml-3" size="3rem" variant="light">
                <i class="fa fa-archive" style="color: #a75323; font-size:1.5rem"></i>
              </b-avatar>
              <h4 class="ml-2 mt-2" style="color: #a75323;">Services</h4>
              <div class="ml-auto mt-2"><h5 style="color: #a75323;">{{services.length}}</h5></div>
            </b-list-group-item>
          </b-media>
        </div>
      </div>
    </div>
 </div>
</template>

<script>
  import { sendRequest }  from "../utils/RequestHelper.js"
  import store from "../store/index.js"

  export default {
    name: 'Home',
    data() {
      return {

        // data
        isLoading: false,
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        currentPage: 1,

        orders: [],
        products: [],
        services: [],
      }
    },


    // methods
    methods: {

      // meta
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },

      getProducts(type) {
        this.pageIsLoading = true;

        let query = ""

        query += '&type=' + type;

        var successCallback = (response) => {
          if(type=='item')
            this.products = response.data.data;
          else if(type == 'service')
            this.services = response.data.data;
          this.pageIsLoading = false;
        };
        var errorCallback = () => {
          this.pageIsLoading = false;
          this.alertType = "danger"
          this.alertText = this.langDec['errorMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', 'dashboard/products?limit=999999&page=' + (this.currentPage) + query, true, null, successCallback, errorCallback);
      },
      getTodayOrders(){
        this.isLoading = true;

        let query = ""

        const date = new Date();

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        // This arrangement can be altered based on how we want the date's format to appear.
        let dateValue = this.date = `${year}-${month}-${day}`;

        // date filter
        query += '&date=' + dateValue

        // date filter
        if(this.dateValue){
          query += '&date=' + this.dateValue
        }

        var successCallback = (response) => {
          this.orders = response.data.data;
          this.isLoading = false;
        };
        var errorCallback = () => {
          this.isLoading = false;
          this.alertType = "danger"
          this.alertText = this.langDec['errorMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', 'dashboard/orders?limit=999999&page=' + (this.currentPage) + query, true, null, successCallback, errorCallback);
      },
    },
    created(){
      this.getTodayOrders();
      this.getProducts('item')
      this.getProducts('service')
    },
  }
</script>
