<template>
  <div class="page-layout users">
     <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="page-header">
      <h3>
        <b>
          {{currentId == 0 ? langDec['add'] : langDec['edit']}}
          {{langDec['admin'] }}
        </b>
      </h3>
      <b-button size="sm" @click="$router.go(-1)" variant="secondary">{{ langDec['back'] }}</b-button>
    </div>
    <div class="text-center text-danger my-2" v-show="pageIsLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <b-form @submit.stop.prevent="onSubmit"  v-show="!pageIsLoading">
      <b-container class="bv-example-row">
        <b-row>


          <!-- name -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-name" :label="langDec['name']" label-for="admin-edit-name">
              <b-form-input
                autofocus
                id="admin-edit-name"
                name="admin-edit-name"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                aria-describedby="input-edit-name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-edit-name">
                {{langDec['nameRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- phone -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-phone" :label="langDec['phone']" label-for="admin-edit-phone">
              <b-form-input
                type="tel"
                id="admin-edit-phone"
                name="admin-edit-phone"
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
                aria-describedby="input-edit-phone"
              ></b-form-input>
              <b-form-invalid-feedback id="input-edit-phone" v-if='!$v.form.phone.required'>
                {{langDec['phoneRequired']}}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.form.phone.minLength || !$v.form.phone.maxLength" id="input-edit-phone">
                {{langDec['phoneLengthRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- email -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-email" :label="langDec['email']" label-for="admin-edit-email">
              <b-form-input
                id="admin-edit-email"
                name="admin-edit-email"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="input-edit-email"
                type="email"
              ></b-form-input>
              <b-form-invalid-feedback id="input-edit-email" v-if='!$v.form.email.required'>
                {{langDec['emailRequired']}}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-edit-email" v-if='!$v.form.email.email'>
                {{langDec['emailValidRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- userPwd -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-userPwd" :label="langDec['password']" label-for="admin-edit-userPwd">
              <b-form-input
                id="admin-edit-userPwd"
                name="admin-edit-userPwd"
                v-model="$v.form.userPwd.$model"
                :state="validateState('userPwd')"
                aria-describedby="input-edit-userPwd"
                type="password"
              ></b-form-input>
              <b-form-invalid-feedback id="input-edit-userPwd" v-if='!$v.form.userPwd.required'>
                {{langDec['passwordRequired']}}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-edit-userPwd" v-if='!$v.form.userPwd.minLength'>
                {{langDec['passwordLengthRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- is_active toggle -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-is_active" :label="langDec['accountStatus']" label-for="admin-edit-is_active">
              <b-form-select
                id="admin-edit-is_active"
                name="admin-edit-is_active"
                v-model="$v.form.is_active.$model"
                :options="accountStatuses"
                :state="validateState('is_active')"
                aria-describedby="input-edit-is_active"
              ></b-form-select>
              <b-form-invalid-feedback id="input-edit-is_active" v-if='!$v.form.is_active.required'>
                {{langDec['accountStatusRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button type="submit" variant="primary">{{ currentId == 0  ? langDec['add'] : langDec['save']}}</b-button>
      </b-container>
    </b-form>
  </div>
</template>

<script>
  import { sendRequest } from "../../../utils/RequestHelper.js"
  import { validationMixin } from "vuelidate";
  import { required, email, numeric, minLength, maxLength } from "vuelidate/lib/validators";
  import store from "../../../store/index.js"

  export default {
    mixins: [validationMixin],
    data() {
      return {

        // data
        pageIsLoading: false,
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        langDec: {},
        role: null,
        currentId: null,
        accountStatuses: [],
        
        lang: null,
        langIsEng: null,
        loggedInUserId: null,


        // form
        form: {
          name: null,
          phone: null,
          email: "",
          is_active: null,
          userPwd: null
        },
      };
    },


    // validations
    validations: {
      form: {
        name: {
          required
        },
        phone: {
          required,
          numeric
        },
        email: {
          required,
          email
        },
        is_active: {
          required
        },
        userPwd: {
          minLength: minLength(8),
        },
      }
    },


    // methods
    methods: {

      // meta
      backToHome(status){
        this.$router.push({
          name: 'users',
          query: {
            role: this.role,
            message: status
          }
        })
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },


      // form
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      onSubmit() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return; 
        }
        if(this.currentId == 0){
          this.createItem();
        }
        else{
          this.saveUser();
        }
      },


      // get, create and edit
      createItem() {
        this.pageIsLoading = true;
        
        this.form.username = this.form.phone
        this.form.role = this.role

        this.form.password = this.form.userPwd

        this.backToHome('save');

        // var successCallback = () => {
        //   this.pageIsLoading = false;
        //   this.alertType = "success"
        //   this.alertText = this.langDec['savedMessage']
        //   this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        //   this.backToHome('save');
        // };
        // var errorCallback = (error) => {

        //   let errors = error.response.data;
        //   let errorMessage = ''
        //   for (const prop in errors) {
        //     if (Object.hasOwn(errors, prop)) {
        //       errorMessage = errors[prop] ;
        //       this.alertText = errorMessage
        //       this.alertType = "danger"
        //       this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        //     }
        //   }
        //   this.pageIsLoading = false;
          
        // };
        // sendRequest('post', 'admins/', true, this.form, successCallback, errorCallback);

      },
      saveUser() {
        this.pageIsLoading = true;

        this.form.password = this.form.userPwd

        if(this.form.userPwd == null || this.form.userPwd == '') {
          delete this.form.password
        }
        delete this.form.userPwd

        this.backToHome('save');

        // var successCallback = () => {
        //   this.pageIsLoading = false;
        //   this.alertType = "success"
        //   this.alertText = this.langDec['savedMessage']
        //   this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        //   this.backToHome('save');
        // };
        // var errorCallback = (error) => {
        //   let errors = error.response.data;
        //   let errorMessage = ''
        //   for (const prop in errors) {
        //     if (Object.hasOwn(errors, prop)) {
        //       errorMessage = errors[prop] ;
        //       this.alertText = errorMessage
        //       this.alertType = "danger"
        //       this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        //     }
        //   }
        //   this.pageIsLoading = false;
        // };
        // sendRequest('patch', 'admins/' + this.$route.params.id + '/', true, this.form, successCallback, errorCallback);
      },
      loadData() {
        this.form = {
          name: 'Ahmed Gamal', email: 'ahmed.g@c.com', phone: '01010111', is_active: true,
        };
        this.form.userPwd = null;
        // this.pageIsLoading = true;
        // var successCallback = (response) => {
        //   this.pageIsLoading = false;
        //   this.form = response.data;
        //   this.form.userPwd = null;
        // };
        // var errorCallback = (error) => {
        //   if(error.response.status == 404) {
        //     this.$router.push({
        //       name: 'home'
        //     })
        //   }
        //   this.pageIsLoading = false;
        //   this.alertType = "danger"
        //   this.alertText = this.langDec['errorMessage']
        //   this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        // };
        // sendRequest('get', 'admins/' + this.$route.params.id + '/', true, null, successCallback, errorCallback);
      }
    },


    // created
    created() {
      this.langDec = store.getters.getLangData
      this.accountStatuses = [
        { value: null, text: this.langDec['choose'] },
        { value: true, text: this.langDec['active'] },
        { value: false, text: this.langDec['disactive'] }
      ]
      this.loggedInUserId = store.getters.userId
      this.role = this.$route.params.role
      this.currentId = this.$route.params.id
      if(this.loggedInUserId == this.currentId){
        this.$router.push({
          name: 'home'
        })
      }
      if(this.currentId != 0) {
        this.loadData();
      }
      this.lang = store.getters.getlang;
      this.langIsEng = false;
    }
  };
</script>