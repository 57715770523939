<template>
  <div v-if="!['login'].includes($route.name)">
    <div class="header">

      <!-- nav toggle -->
      <a href="#" class="nav-trigger" @click="navToogle">
        <span></span>
      </a>

      <!-- logo div -->
      <div class="logo">
        <span>{{langDec['dashboard']}}<small class="text-muted">  v 1.0.0 </small></span>
      </div>

      <!-- top nav buttons -->
      <div class="logoutBtn-div">
        
        <!-- user dropdown -->
        <b-dropdown size="sm" id="dropdown-1" :right="langIsEng" :text="username" class="mt-2 logoutBtn"  variant="outline-secondary">
          
          <!-- languages toggle -->
          <!-- <b-form-radio-group
            @change="changeLang()"
            style="padding-inline-start: 22px; padding-bottom: 10px"
            size="sm"
            id="btn-radios-1"
            v-model="currentLang"
            button-variant="outline-primary"
            :options="[{ text: 'En', value: 'en' }, { text: 'ع ر', value: 'ar' },]"
            buttons
          ></b-form-radio-group> -->

          <!-- account settings btn -->
          <b-dropdown-item @click="goToSettingsRouter('editProfile')">
            {{langDec['editAccount']}}
          </b-dropdown-item>

          <!-- logout btn -->
          <b-dropdown-item v-b-modal.modal-logout>
            {{langDec['logout']}}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>


    <!-- side nav -->
    <div class="side-nav" :class="{ visible: isVisible }" v-if="!['NotFound'].includes($route.name)">
      <nav class="nav-body">
        <ul style="margin-top: 52px;">


          <!-- homepage -->
          <li @click="isVisible = false" v-b-tooltip.hover.left="langDec['homepage']">
            <router-link class="nav-link" to="/">
              <i class="fa fa-home" style="font-size: 19px"></i>
              <span class="hideInSmall">
                {{langDec['homepage']}}
              </span>
            </router-link>
          </li>


          <!-- orders -->
          <li @click="isVisible = false" v-b-tooltip.hover.left="langDec['orders']">
            <router-link class="nav-link" to="/orders">
              <i class="fa fa-shopping-cart" style="font-size: 19px"></i>
              <span class="hideInSmall">
                {{langDec['orders']}}
              </span>
            </router-link>
          </li>
          

          <!-- prducts -->
          <li @click="isVisible = false" v-b-tooltip.hover.left="langDec['products']">
            <router-link class="nav-link" to="/products" >
              <i class='fa fa-archive' style='color:#35475e'></i>
              <span class="hideInSmall">
                {{langDec['products']}}
              </span>
            </router-link>
          </li>


          <!-- services -->
          <li @click="isVisible = false" v-b-tooltip.hover.left="langDec['services']">
            <router-link class="nav-link" to="/services" >
              <i class='fa fa-archive' style='color:#35475e'></i>
              <span class="hideInSmall">
                {{langDec['services']}}
              </span>
            </router-link>
          </li>


          <!-- users -->
          <li v-if="false" @click="isVisible = false" v-b-tooltip.hover.left="langDec['admins']">
            <router-link class="nav-link" to="/users" >
              <i class='fa fa-users' style='color:#35475e'></i>
              <span class="hideInSmall">
                {{langDec['admins']}}
              </span>
            </router-link>
          </li>

          
          <!-- slides -->
          <li @click="isVisible = false" v-b-tooltip.hover.left="langDec['slides']">
            <router-link class="nav-link" to="/slides" >
              <i class='fa fa-image' style='color:#35475e'></i>
              <span class="hideInSmall">
                {{langDec['slides']}}
              </span>
            </router-link>
          </li>

          
          <!-- locations -->
          <li @click="isVisible = false" v-b-tooltip.hover.left="langDec['settings']">
            <router-link class="nav-link" to="/settings" >
              <i class='fa fa-gear' style='color:#35475e'></i>
              <span class="hideInSmall">
                {{langDec['settings']}}
              </span>
            </router-link>
          </li>

        </ul>
      </nav>
    </div> 


    <!-- modals -->


    <!-- logout modal -->
    <b-modal size="sm" id="modal-logout" :title="langDec['logout']">
      <p class="my-4">{{langDec['logoutLabel']}}</p>
      <template v-slot:modal-footer>
      <b-button size="sm" variant="secondary" @click="$bvModal.hide('modal-logout')">
        {{langDec['cancel']}}
      </b-button>
      <b-button size="sm" variant="primary" @click="logout()">
        {{ langDec['logout'] }}
      </b-button>
    </template>
    </b-modal>
  </div>
</template>

<script>
import store from "../../store/index.js"

export default {
  data() {
    return {

      // data
      lang: null,
      langIsEng: null,
      currentLang: null,
      isVisible: false,
      username: null,

    };
  },

  
  // watch
  watch: {
    $route(to, from) {
      this.username = this.$store.getters.username;
    },
  },


  // methods
  methods: {


    // meta
    goToSettingsRouter() {
      this.$router.push({
        name: 'editProfile'
      })
    },
    navToogle() {
      this.isVisible = !this.isVisible;
    },
    // changeLang() {
    //   if(this.currentLang == 'ar'){
    //     store.commit("SET_LANG", "ar");
    //   }
    //   else {
    //     store.commit("SET_LANG", "en");
    //   }
    //   location.reload();
    // },


    // logout
    logout() {
      // var successCallback = () => {
        store.commit("LOGOUT");
        this.$router.push({
          name: "login"
        })
      // };
      // var errorCallback = () => {
      // };
      // sendRequest('get', '/admins/logout', true, null, successCallback, errorCallback);
    },
  },


  // created
  created() {
    this.username = store.getters.username ? store.getters.username : 'مسئول'
    this.lang = store.getters.getlang;
    this.currentLang = this.lang;
    this.langIsEng = this.lang == 'en'
    this.langDec = store.getters.getLangData
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #d5dae5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 3;
  height: 50px;
  background-color: #fff;
  border-bottom: 3px solid #ffffff;
  -webkit-box-shadow: 0 5px 5px -6px rgb(87, 87, 87);
  -moz-box-shadow: 0 5px 5px -6px rgb(87, 87, 87);
  box-shadow: 0 5px 5px -6px rgb(87, 87, 87);
}
.darkHeader {
  position: fixed;
  width: 100%;
  z-index: 3;
  height: 50px;
  background-color: #606060 !important;
  color: white !important;
  border-bottom: 3px solid #ffffff;
  -webkit-box-shadow: 0 5px 5px -6px rgb(87, 87, 87);
  -moz-box-shadow: 0 5px 5px -6px rgb(87, 87, 87);
  box-shadow: 0 5px 5px -6px rgb(87, 87, 87);
}
ul .nav-link{
  padding: 0px;
  transition: 0.3s;
}
.top-nav-div{
  margin-left: 10px;
}
.nav-body:lang(ar) {
  height: -webkit-fill-available; -webkit-box-shadow:rgb(134 134 134) -6px -1px 4px -6px;-moz-box-shadow:rgb(134 134 134) -6px -1px 4px -6px; box-shadow: rgb(134 134 134) -6px -1px 4px -6px;
}
.nav-body:lang(en) {
  height: -webkit-fill-available; -webkit-box-shadow:rgb(134 134 134) -6px -1px 4px -6px;-moz-box-shadow:rgb(134 134 134) -6px -1px 4px -6px; box-shadow: rgb(134 134 134) -4px 7px 6px 5px;
}
.top-nav-btn .nav-link{
  padding: 10px;
}
ul .nav-link:hover:lang(ar){
  padding-right: 20px;
  border-right: #35475e 4px solid;
}
ul .nav-link:hover:lang(en){
  padding-left: 20px;
  border-left: #35475e 4px solid;
}
/* logo in header(mobile version) and side-nav (tablet & desktop) */
.logo {
  height: 50px;
  padding: 10px;
  font-weight: 700;
}
.header{
  z-index: 100 !important;
}
.header .logo {
  color: #233245;
}
.side-nav .logo {
  background-color: #ffffff;
  color: #233245;
}
.header .logo {
  float: left;
}
.header .logo {
  height: 55px;
  padding: 10px;
  font-weight: 700;
  color: #233245;
}
.logo i {
  font-size: 22px;
}
 .logo span {
  margin-top: 2px;
  margin-right: 5px;
  text-transform: uppercase;
}
.logoImg{
  width: 20px;
}
.header:lang(ar) .nav-trigger {
  position: relative;
  float: right;
  width: 20px;
  height: 50px;
  right: 30px;
  display: none;
}
.header:lang(en) .nav-trigger {
  position: relative;
  float: right;
  width: 20px;
  height: 50px;
  right: 20px;
  display: none;
}
.nav-trigger span,
.nav-trigger span:before,
span:after {
  width: 20px;
  height: 2px;
  background-color: #35475e;
  position: absolute;
}
.nav-trigger span {
  top: 50%;
}
.nav-trigger span:before,
.nav-trigger span:after {
  content: "";
}
.nav-trigger span:before {
  top: -6px;
}
.nav-trigger span:after {
  top: 6px;
}
/* side navigation styles */
.side-nav {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 1;
  display: none;
}
.side-nav.visible {
  display: block;
  position: fixed;
}
.side-nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.side-nav ul li {
  position: relative;
}
ul .router-link-exact-active:lang(ar){
  background-color: #ebebeb;
  border-right: #35475e 4px solid;
  padding-right: 12px !important;
}
ul .router-link-exact-active:lang(en){
  background-color: #ebebeb;
  border-left: #35475e 4px solid;
  padding-left: 12px !important;
}
.side-nav ul li a{
  padding: 10px 16px;
  color: #35475e;
}
.side-nav ul li.active:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #fff !important;
}
.router-link-exact-active:has(li){
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #fff !important;
}
.side-nav ul li a {
  color: #35475e;
  display: block;
  text-decoration: none;
}
.side-nav ul li i {
  color: #35475e;
  min-width: 20px;
  text-align: center;
}
.side-nav:lang(ar) ul li span:nth-child(2) {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}
.side-nav:lang(en) ul li span:nth-child(2) {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
}

/* main content styles */
.main-content {
  padding: 40px;
  margin-top: 0;
  padding: 0;
  padding-top: 50px;
  height: 100%;
}
.main-content .title {
  background-color: #eef1f7;
  border-bottom: 1px solid #b8bec9;
  padding: 10px 20px;
  font-weight: 700;
  color: #333;
  font-size: 18px;
}
.header:lang(ar) .logoutBtn {
  float: left;
  color:red;
  margin-left: 15px;
  margin-top: 10px !important;
}
.header:lang(en) .logoutBtn{
  float: right;
  color:red;
  margin-right: 15px;
  margin-top: 10px !important;
}
.logoutBtn button{
  background-color: transparent;
}

.btn-group>.btn{
  background-color: transparent !important;
}

.top-nav-btn{
  float: left;
  /* margin-top: 15px;
  margin-left: 20px; */
}
/* set element styles to fit tablet and higher(desktop) */
@media screen and (min-width: 600px) {
  .header {
    background-color: #ffffff;
    z-index: 1;
    position: fixed;
  }
  .header .logo {
    /* display: none; */
    float: inline-start !important;
  }
  .nav-trigger {
    display: none;
  }
  .nav-trigger span,
  .nav-trigger span:before,
  span:after {
    background-color: #fff;
  }
  .side-nav {
    display: block;
    width: 50px;
    z-index: 2;
  }
  .side-nav ul li span:nth-child(2) {
    display: none;
  }
  .side-nav .logo i {
    padding-right: 12px;
  }
  .side-nav .logo span {
    display: none;
  }
  .side-nav ul li i {
    font-size: 15px;
  }
  .side-nav ul li a {
    text-align: center;
  }
  .main-content {
    margin-right: 70px;
  }   
  .btn-group>.btn{
    background-color: transparent !important;
  }
}
/* set element styles for desktop */
@media screen and (min-width: 1100px) {
  .side-nav {
    width: 4%;
  }
  .side-nav ul li span:nth-child(2) {
    display: inline-block;
  }
  .side-nav ul li i {
    font-size: 16px;
  }
  .side-nav ul li:lang(ar) a {
    text-align: right;
  }
  .side-nav ul li:lang(en) a {
    text-align: left;
  }
  .side-nav .logo i {
    padding-right: 0;
  }
  .side-nav .logo span {
    display: inline-block;
  }
  .main-content {
    margin-right: 200px;
  }
  .dropdown-toggle{
    color: white !important;
  }
  .btn-group>.btn{
    background-color: transparent !important;
  }
}
/* main box container */
.main {
  display: flex;
  flex-flow: row wrap;
}
.widget {
  flex-basis: 300px;
  flex-grow: 10;
  height: 300px;
  margin: 15px;
  border-radius: 6px;
  background-color: #ffffff;
  position: relative;
}
.widget .title {
  background-color: #eef1f7;
  border-bottom: 1px solid #dfe4ec;
  padding: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #617085;
  font-weight: 600;
}
.ad {
  width: 350px;
  height: 300px;
  border: 1px solid #ddd;
}
.btn-group>.btn{
  background-color: transparent !important;
}
.smallModal{
  max-width: 400px !important;
}
.hideInSmall{
  display: none !important;
}
@media (max-width: 600px) {
  .header:lang(en) .nav-trigger {
    display: block;
  }
  .header:lang(ar) .nav-trigger {
    display: block;
  }
  .header .logo{
    margin-right: 30px;
    margin-top: 3px;
  }
  .hideInSmall{
    display: inline !important;
  }
}

@media screen and (max-width: 600px) {
  .logoutBtn{
    margin-right: 38px !important;
  }
}
</style>