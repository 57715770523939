<template>
  <div class="page-layout users">
     <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    <div class="page-header">
      <h3>
        <b>
          {{currentId == 0 ? langDec['add'] : langDec['edit']}}
          {{langDec['order'] }}
        </b>
      </h3>
      <b-button size="sm" @click="$router.go(-1)" variant="secondary">{{ langDec['back'] }}</b-button>
    </div>
    <div class="text-center text-danger my-2" v-show="pageIsLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>
    <b-form @submit.stop.prevent="onSubmit"  v-show="!pageIsLoading">
      <b-container class="bv-example-row">
        <b-row>

          <!-- name -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-name" :label="langDec['name']" label-for="admin-edit-name">
              <b-form-input
                autofocus
                id="admin-edit-name"
                name="admin-edit-name"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                aria-describedby="input-edit-name"
              ></b-form-input>
              <b-form-invalid-feedback id="input-edit-name">
                {{langDec['nameRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- company -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-company" :label="langDec['company']" label-for="admin-edit-company">
              <b-form-input
                id="admin-edit-company"
                name="admin-edit-company"
                v-model="$v.form.company.$model"
                :state="validateState('company')"
                aria-describedby="input-edit-company"
              ></b-form-input>
              <b-form-invalid-feedback id="input-edit-nacompanyme">
                {{langDec['companyRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- phone -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-phone" :label="langDec['phone']" label-for="admin-edit-phone">
              <b-form-input
                type="tel"
                id="admin-edit-phone"
                name="admin-edit-phone"
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
                aria-describedby="input-edit-phone"
              ></b-form-input>
              <b-form-invalid-feedback id="input-edit-phone" v-if='!$v.form.phone.required'>
                {{langDec['phoneRequired']}}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.form.phone.minLength || !$v.form.phone.maxLength" id="input-edit-phone">
                {{langDec['phoneLengthRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- email -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-email" :label="langDec['email']" label-for="admin-edit-email">
              <b-form-input
                type="email"
                id="admin-edit-email"
                name="admin-edit-email"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="input-edit-email"
              ></b-form-input>
              <b-form-invalid-feedback id="input-edit-email" v-if='!$v.form.email.required'>
                {{langDec['emailRequired']}}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.form.email.email" id="input-edit-email">
                {{langDec['emailValidRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- message -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-message" :label="langDec['message']" label-for="admin-edit-message">
              <b-form-textarea
                rows="4"
                id="admin-edit-message"
                name="admin-edit-message"
                v-model="$v.form.message.$model"
                :state="validateState('message')"
                aria-describedby="input-edit-message"
              ></b-form-textarea>
              <b-form-invalid-feedback id="input-edit-message" v-if='!$v.form.message.required'>
                {{langDec['messageRequired']}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>


          <!-- product_id -->
          <b-col lg="6" md="12">
            <b-form-group id="group-admin-edit-product_id" :label="langDec['product']" label-for="admin-edit-product_id">
              <b-form-select
                id="admin-edit-product_id"
                name="admin-edit-product_id"
                v-model="form.product_id"
                :options="productsList"
                aria-describedby="input-edit-product_id"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>


        </b-row>
        <b-button type="submit" variant="primary">{{ currentId == 0  ? langDec['add'] : langDec['save']}}</b-button>
      </b-container>
    </b-form>
  </div>
</template>

<script>
  import { sendRequest } from "../../../utils/RequestHelper.js"
  import { validationMixin } from "vuelidate";
  import { required, email, numeric, minLength, maxLength } from "vuelidate/lib/validators";
  import store from "../../../store/index.js"

  export default {
    mixins: [validationMixin],
    data() {
      return {

        // data
        pageIsLoading: false,
        alertCountdown: 0,
        alertType: '',
        alertText: '',
        langDec: {},
        role: null,
        currentId: null,
        accountStatuses: [],
        
        lang: null,
        langIsEng: null,


        // form
        form: {
          name: null,
          company: null,
          phone: null,
          email: null,
          message: null,
          product_id: null,
        },

        productsList: [],
      };
    },


    // validations
    validations: {
      form: {
        name: {
          required
        },
        phone: {
          required,
          numeric,
        },
        email: {
          required,
          email
        },
        company: {
          required
        },
        message: {
          required,
        },
        product_id: {
          required
        }
      }
    },


    // methods
    methods: {

      // meta
      backToHome(status){
        this.$router.push({
          name: 'orders',
          query: {
            message: status
          }
        })
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },


      // form
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      onSubmit() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return; 
        }
        if(this.currentId == 0){
          this.createItem();
        }
        else{
          this.saveUser();
        }
      },


      // get, create and edit
      createItem() {
        this.pageIsLoading = true;

        var successCallback = () => {
          this.pageIsLoading = false;
          this.alertType = "success"
          this.alertText = this.langDec['savedMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.backToHome('save');
        };
        var errorCallback = (error) => {

          let errors = error.response.data;
          let errorMessage = ''
          for (const prop in errors) {
            if (Object.hasOwn(errors, prop)) {
              errorMessage = errors[prop] ;
              this.alertText = errorMessage
              this.alertType = "danger"
              this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            }
          }
          this.pageIsLoading = false;
          
        };
        sendRequest('post', 'dashboard/orders', true, this.form, successCallback, errorCallback);

      },
      saveUser() {
        this.pageIsLoading = true;
        var successCallback = () => {
          this.pageIsLoading = false;
          this.alertType = "success"
          this.alertText = this.langDec['savedMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          this.backToHome('save');
        };
        var errorCallback = (error) => {
          let errors = error.response.data;
          let errorMessage = ''
          for (const prop in errors) {
            if (Object.hasOwn(errors, prop)) {
              errorMessage = errors[prop] ;
              this.alertText = errorMessage
              this.alertType = "danger"
              this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            }
          }
          this.pageIsLoading = false;
        };
        sendRequest('patch', 'dashboard/orders/' + this.$route.params.id, true, this.form, successCallback, errorCallback);
      },
      loadData() {
        this.pageIsLoading = true;
        var successCallback = (response) => {
          this.pageIsLoading = false;
          this.form = response.data.data;
          let productId = this.form.product_id?.id
          this.form.product_id = productId
        };
        var errorCallback = (error) => {
          if(error.response.status == 404) {
            this.$router.push({
              name: 'home'
            })
          }
          this.pageIsLoading = false;
          this.alertType = "danger"
          this.alertText = this.langDec['errorMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', 'dashboard/orders/' + this.$route.params.id, true, null, successCallback, errorCallback);
      },


      getProducts() {
        
        var successCallback = (response) => {
          this.productsList = response.data.data;
          this.tableIsLoading = false;
          this.pageIsLoading = false;
        };
        var errorCallback = () => {
          this.tableIsLoading = false;
          this.pageIsLoading = false;
          this.alertType = "danger"
          this.alertText = this.langDec['errorMessage']
          this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        };
        sendRequest('get', 'dashboard/products?limit=9999&page=1', true, null, successCallback, errorCallback);
      },
    },


    // created
    created() {
      this.langDec = store.getters.getLangData
      this.accountStatuses = [
        { value: null, text: this.langDec['choose'] },
        { value: true, text: this.langDec['active'] },
        { value: false, text: this.langDec['disactive'] }
      ]
      this.role = this.$route.params.role
      this.currentId = this.$route.params.id
      this.getProducts();
      if(this.currentId != 0) {
        this.loadData();
      }
      this.lang = store.getters.getlang;
      this.langIsEng = false;
    }
  };
</script>