<template>
  <div class="page-layout items">
     <b-alert :show="alertCountdown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged" >
      {{alertText}}
    </b-alert>
    
    <div class="page-header">
      
      <h3>
        <b>
          {{langDec['admins'] }}
        </b>
        <b-button type="button" size="lg" variant="outline-secondary" class="btn btn-primary-outlined options-admin-btn edit-options-admin-btn" @click="refreshData();" ><i class="fa fa-refresh"></i></b-button>
      </h3>


      <!-- <div class="filter-box">
        <div>
          <b-form-input
            @input="loadData(currentPage)"
            v-model="search"
            :placeholder="langDec['search']"
          ></b-form-input>
        </div>
        <span v-if="search != ''" class="filterControl">
          <i @click="search = '';loadData(currentPage)" class="fa fa-times"></i>
        </span>
      </div> -->

    </div>

    <!-- loading -->
    <div class="text-center text-danger my-2 loadingBox" v-show="pageIsLoading">
      <b-spinner class="align-middle"></b-spinner>
    </div>

    <!-- table -->
    <div class="row"  v-show="!pageIsLoading">
      <div class="col-md-12">
        <div class="">
          <div class="">
            <div class="table-responsive">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th width="5%">
                      <span class="tbl-data">#</span>
                    </th>
                    <th>
                      <div class="tbl-data">{{langDec['name']}}</div>
                    </th>
                    <th>
                      <span class="tbl-data">{{langDec['email']}}</span>
                    </th>
                    <th>
                      <span class="tbl-data">{{langDec['phone']}}</span>
                    </th>
                    <th width="15%" class="td-option">
                      <span clas="tbl-data">	
                        <b-button @click="goToRouter(0)" size="sm" class="btn btn-success add-admin-btn options-admin-btn" data-toggle="modal" v-b-tooltip.hover.bottom="langDec['add']"><i class="fa fa-plus mx-2"></i>
                        </b-button>	
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="no-data-row" v-if="items.length == 0 && tableIsLoading">
                    <td colspan="7">
                      <div class="text-center >text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </td>
                  </tr>
                  <tr class="no-data-row" v-if="items.length == 0 && !tableIsLoading">
                    <td colspan="7">
                      No data
                    </td>
                  </tr>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>
                      {{from+index}}
                    </td>
                    <td>
                      <div class="tbl-data">{{item.name}}<b-badge class="mx-1" :variant="item.is_active ? 'success':'danger'"><small>{{ item.is_active ? langDec['active'] : langDec['disactive'] }}</small></b-badge></div>
                    </td>
                    <td>
                      <span class="tbl-data">{{item.email}}</span>
                    </td>
                    <td>
                      <span class="tbl-data">{{item.phone}}</span>
                    </td>
                    <td class="td-option">
                      <div v-if="loggedInUserId == item.id" class="mt-2"><b-badge class="mr-1" :variant="'secondary'">الحساب خاص بك</b-badge></div>
                      <div v-if="loggedInUserId != item.id" class="form-group row-option">
                        <b-button v-if="loggedInUserId != item.id" type="button" size="sm" class="btn btn-primary options-admin-btn edit-options-admin-btn" @click="goToRouter(item.id)" v-b-tooltip.hover.bottom="langDec['edit']"><i class="fa fa-edit mx-2"></i></b-button>
                        <b-button v-if="loggedInUserId != item.id" type="button" size="sm" class="btn btn-danger options-admin-btn del-options-admin-btn" data-toggle="modal" data-target="#delAdmin" @click="openDeleteItem(item.id);" v-b-tooltip.hover.bottom="langDec['delete']"><i class="fa fa-trash mx-2"></i></b-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination v-model="currentPage" :total-rows="total" :per-page="25" align="center" size="sm"></b-pagination>
          </div>
        </div>
      </div>
    </div>


    <!-- modals -->


    <!-- delete modal -->
    <b-modal v-model="delModal" centered title="Delete ">
      <p class="my-4">Want to delete this item?</p>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button auto variant="primary" size="sm" class="float-left mr-1" @click="deleteItem()">
            Delete
          </b-button>
          <b-button variant="secondary" size="sm" class="float-left" @click="delModal=false">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import { sendRequest }  from "../../../utils/RequestHelper.js"
  import store from "../../../store/index.js"

  export default {
    name: 'Home',
    data() {
      return {
        
        // data
        pageIsLoading: false,
        tableIsLoading: true,
        alertType: '',
        alertText: '',
        alertCountdown: 0,
        loggedInUserId: null,
        langDec: {},
        api: 'users',


        // tables, filters and paginations
        items:[],
        search: '',
        currentPage: 1,
        total: null,
        from: 1,

        
        // modals
        currentID: null,
        delModal: false,
      }
    },


    // watch
    watch: {
      currentPage: {
        handler: function(value) {
          this.loadData(value);
        }
      },
      search() {
        if(this.search == '')
          this.resetSearch();
      }
    },


    // methods
    methods: {

      // meta
      goToRouter(id){
        this.$router.push({
          name: 'user',
          params: {
            id
          }
        })
      },
      countDownChanged(alertCountdown) {
        this.alertCountdown = alertCountdown
      },


      
      // load data
      refreshData() {
        this.loadData(this.currentPage);
      },
      resetSearch(){
        this.search = null;
        this.loadData(this.currentPage);
      },  
      loadData(){
        this.items = [
          {
            name: 'Ahmed Gamal', email: 'ahmed.g@c.com', phone: '01010111', is_active: true
          }
        ]

        // this.pageIsLoading = true;
        // this.tableIsLoading = true;

        // let query = ""

        // // delivery filter
        // if(this.search){
        //   query += '&search=' + this.search
        // }


        // var successCallback = (response) => {
        //   this.items = response.data.data;
        //   this.total = response.data.total
        //   this.tableIsLoading = false;
        //   this.pageIsLoading = false;
        // };
        // var errorCallback = () => {
        //   this.tableIsLoading = false;
        //   this.pageIsLoading = false;
        //   this.alertType = "danger"
        //   this.alertText = this.langDec['errorMessage']
        //   this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        // };
        // sendRequest('get', userType + '/?limit=25&offset=' + (this.currentPage) + query, true, null, successCallback, errorCallback);
      },


      // modals



      // delete modal
      openDeleteItem(id){
        if(id) {
          this.delModal = true;
          this.currentID = id;
        }
      },


      // delete api
      deleteItem() {
        this.pageIsLoading = true;
        this.tableIsLoading = true;
        if(this.currentID) {
          this.tableIsLoading = true;
          var successCallback = () => {
            this.alertType = "success"
            this.alertText = "Deleted"
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
            this.delModal = false;
            this.loadData();
          };
          var errorCallback = () => {
            this.tableIsLoading = false;
            this.alertType = "danger"
            this.alertText = this.langDec['errorMessage']
            this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
          };
          sendRequest('delete', 'admins/' + this.currentID + '/', true, null, successCallback, errorCallback);
        }
      },
    },


    // created
    created() {
      this.langDec = store.getters.getLangData
      this.loggedInUserId = store.getters.userId
      if (this.$route.query.message) {
        this.alertType = "success"
        this.alertText = this.langDec['savedMessage']
        this.alertCountdown = process.env.VUE_APP_ALERT_COUNTDOWN;
        this.$router.push({
          name: 'users',
        })
      }
      this.loadData(this.currentPage);
    },
  }
</script>
<style scoped>
  @import '../../../assets/css/tableContent.css';
</style>